import { FaExclamationTriangle } from "react-icons/fa";
import * as S from "./styles";
import { forwardRef, useState, useRef } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useResponsive } from "../../../../hooks/useResponsive";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  value: string;
  icon: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  isPassword?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { icon, label, onChange, value, errorMessage, isPassword, type, ...rest },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const { isResponsive } = useResponsive();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleFocus = () => {
      if (isResponsive && inputRef.current) {
        inputRef.current.focus();
        setIsFocused(true);
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    return (
      <S.Container onClick={handleFocus}>
        <S.Label>{label}</S.Label>
        <S.InputContainer
          className={`container ${isFocused ? "focused" : ""}`}
          filled={!!value}
          onClick={handleFocus}
        >
          {icon}
          <S.Input
            {...rest}
            ref={(node) => {
              if (typeof ref === "function") ref(node);
              inputRef.current = node;
            }}
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={isPassword ? (showPassword ? "text" : "password") : type}
          />
          {isPassword &&
            (showPassword ? (
              <FiEye
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <FiEyeOff
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword(!showPassword)}
              />
            ))}
        </S.InputContainer>

        {!!errorMessage && (
          <S.ErrorContainer>
            <FaExclamationTriangle />
            <label>{errorMessage}</label>
          </S.ErrorContainer>
        )}
      </S.Container>
    );
  },
);

Input.displayName = "Input";

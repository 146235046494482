import { FormEvent, useState } from "react";
import { MdOutlineEmail, MdKey } from "react-icons/md";
import PackageJson from "../../../package.json";
import { Logo } from "./components/Logo";
import { useAuth } from "../../hooks/auth";
import { SignInButton } from "./components/SignInButton";
import { Input } from "./components/Input";

import * as Yup from "yup";

import * as S from "./styles";
import { useResponsive } from "../../hooks/useResponsive";

export function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { isResponsive } = useResponsive();
  const { signIn, isLoading } = useAuth();

  async function handleSignIn(event: FormEvent) {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required("Enter your password"),
        email: Yup.string().required("Invalid email").email("Invalid email"),
      });

      const data = { email, password };

      await schema.validate(data);

      setError("");

      await signIn({
        email,
        password,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.message);
      }
    }
  }

  return (
    <S.Container>
      <S.Content onSubmit={(event) => handleSignIn(event)}>
        <S.ImageContainer>
          <Logo />
        </S.ImageContainer>

        <S.InputAlign>
          <Input
            label="Email"
            icon={<MdOutlineEmail />}
            type="email"
            name="value"
            value={email}
            errorMessage={error}
            onChange={(value) => {
              setError("");
              setEmail(value.target.value);
            }}
          />

          <Input
            label="Password"
            isPassword
            name="password"
            autoComplete="new-password"
            value={password}
            onChange={(value) => {
              setError("");
              setPassword(value.target.value);
            }}
            icon={<MdKey />}
            errorMessage={error}
          />
        </S.InputAlign>

        <SignInButton
          type="submit"
          title="Sign In"
          isLoading={isLoading}
          width="100%"
          height="3.5rem"
        />

        <S.ForgotPasswordButton to="/forgot-password">
          I forgot my password
        </S.ForgotPasswordButton>

        <S.VersionContainer>
          <p>Version {PackageJson.version}</p>
        </S.VersionContainer>
      </S.Content>
    </S.Container>
  );
}

import styled from "styled-components";

export const Container = styled.main`
  padding: 2rem;
`;

export const Content = styled.section`
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    margin-top: 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 0.5rem;
`;

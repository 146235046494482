import React, { useState } from "react";
import { Drawer } from "vaul";
import * as S from "./styles";

interface DrawerComponentProps {
  triggerContent: React.ReactNode;
  width?: string;
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  title?: string;
  description?: string;
  variant?: "filter" | "default";
}

export function DrawerComponent({
  triggerContent,
  width,
  children,
  open,
  onOpenChange,
  title = "",
  description = "",
  variant = "default",
}: DrawerComponentProps) {

  const isControlled = typeof open !== "undefined";
  const [internalOpen, setInternalOpen] = useState(false);
  const drawerOpen = isControlled ? (open ?? false) : internalOpen;

  function handleSetOpen(newState: boolean) {
    if (isControlled) {
      onOpenChange && onOpenChange(newState);
    } else {
      setInternalOpen(newState);
    }
  }

  return (
    <Drawer.Root open={drawerOpen} onClose={() => handleSetOpen(false)}>
      <S.Trigger onClick={() => handleSetOpen(true)}>{triggerContent}</S.Trigger>
      <Drawer.Portal>
        <S.Overlay />
        <S.Content width={width} variant={variant}>
          <Drawer.Title>{title}</Drawer.Title>
          <Drawer.Description>{description}</Drawer.Description>
          {children}
        </S.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

import * as Dialog from "@radix-ui/react-dialog";
import { GrAdd, GrFormSubtract } from "react-icons/gr";
import ReactLoading from "react-loading";
import { useEffect, useState } from "react";
import { useContextSelector } from "use-context-selector";
import { UsersPermissionsContext } from "../../../../../../contexts/UsersPermissions";
import { Button } from "../../../../../../components/New/Button";
import { Checkbox } from "../../../../../../components/New/Checkbox";
import { useTheme } from "styled-components";
import * as S from "./styles";

interface PermissionsModalProps {
  userId?: string;
}

export function PermissionsModal({ userId }: PermissionsModalProps) {
  const {
    permissions,
    handleExpandPermissions,
    handleCheckPermissions,
    handleCheckOrRemoveAllPermissions,
    updateUserPermissions,
    permissionMessage,
    loadings,
  } = useContextSelector(UsersPermissionsContext, (context) => {
    return context;
  });

  const { webPermissions, appPermissions } = {
    webPermissions: permissions.WEB ? Object.values(permissions.WEB) : null,
    appPermissions: permissions.APP ? Object.values(permissions.APP) : null,
  };

  const [allChecked, setAllChecked] = useState(false);

  const { newColors } = useTheme();

  const hasSelectAllChecked = () => {
    const allWebChecked =
      webPermissions &&
      webPermissions.every((permission) => {
        return (
          permission?.hasPermission &&
          permission?.data?.every((child) => {
            return (
              child?.hasPermission &&
              child?.data?.every((grandChild) => grandChild?.hasPermission)
            );
          })
        );
      });

    const allAppChecked =
      appPermissions &&
      appPermissions.every((permission) => {
        return (
          permission && permission.data?.every((child) => child.hasPermission)
        );
      });

    if (allAppChecked && allWebChecked) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  useEffect(() => {
    hasSelectAllChecked();
  }, [permissions]);

  return (
    <S.Content>
      <S.SubTitleContainer>
        <S.ModalDescription>
          Select the specific permissions to assign to this user
        </S.ModalDescription>
        <S.SelectAllButton
          onClick={() => handleCheckOrRemoveAllPermissions(!allChecked)}
        >
          <Checkbox
            checked={allChecked}
            onClick={() => handleCheckOrRemoveAllPermissions(!allChecked)}
          />
          <span>Select all</span>
        </S.SelectAllButton>
      </S.SubTitleContainer>

      <S.PermissionsContent permissionsLoading={loadings.getUsersPermissions}>
        {loadings.getUsersPermissions ? (
          <ReactLoading height={36} width={36} type={"spin"} color="#232A3B" />
        ) : (
          <>
            <S.PermissionsWrapper>
              <S.PermissionType>Web</S.PermissionType>

              <S.PermissionsList>
                {webPermissions &&
                  webPermissions.map((permission) => (
                    <li key={permission?.name ?? ""}>
                      <S.PermissionItem>
                        {permission?.modal && permission.data?.length ? (
                          <S.IconContainer>
                            <GrFormSubtract
                              size={18}
                              color={newColors["gray-900"]}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                permission &&
                                  handleExpandPermissions(
                                    permission?.environment,
                                    permission?.menu,
                                  );
                              }}
                            />
                          </S.IconContainer>
                        ) : (
                          <S.IconContainer>
                            <GrAdd
                              size={16}
                              color={newColors["gray-900"]}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                permission &&
                                  handleExpandPermissions(
                                    permission?.environment,
                                    permission?.menu,
                                  );
                              }}
                            />
                          </S.IconContainer>
                        )}

                        <Checkbox
                          checked={!!permission?.hasPermission}
                          onClick={() =>
                            permission &&
                            handleCheckPermissions(
                              permission.environment,
                              permission.menu,
                              permission.name,
                              !permission?.hasPermission,
                            )
                          }
                        />

                        {/* <input
                          type="checkbox"
                          id={`permission-${permission?.name}`}
                          checked={permission?.hasPermission}
                          onChange={(event) =>
                            permission &&
                            handleCheckPermissions(
                              permission.environment,
                              permission.menu,
                              permission.name,
                              event.target.checked,
                            )
                          }
                        /> */}
                        <label htmlFor={`permission-${permission?.name}`}>
                          {permission?.menu}
                        </label>
                      </S.PermissionItem>

                      {permission?.modal && (
                        <>
                          {permission?.data?.map((child) => (
                            <S.ExpandGroup key={child.name}>
                              <S.PermissionItem>
                                <Checkbox
                                  checked={child.hasPermission}
                                  onClick={() =>
                                    permission &&
                                    handleCheckPermissions(
                                      child.environment,
                                      child.menu,
                                      child.name,
                                      !child.hasPermission,
                                    )
                                  }
                                />
                                {/* <input
                                  type="checkbox"
                                  id={`permission-${child.name}`}
                                  checked={child.hasPermission}
                                  onChange={(event) =>
                                    handleCheckPermissions(
                                      child.environment,
                                      child.menu,
                                      child.name,
                                      event.target.checked,
                                    )
                                  }
                                /> */}
                                <label htmlFor={`permission-${child.name}`}>
                                  {child.name}
                                </label>
                              </S.PermissionItem>

                              {child.data?.map((grandChild) => (
                                <S.PermissionItem key={grandChild.name}>
                                  <Checkbox
                                    checked={grandChild.hasPermission}
                                    onClick={() =>
                                      permission &&
                                      handleCheckPermissions(
                                        grandChild.environment,
                                        grandChild.menu,
                                        grandChild.name,
                                        !grandChild.hasPermission,
                                      )
                                    }
                                  />

                                  {/* <input
                                    type="checkbox"
                                    id={`permission-${grandChild.name}`}
                                    checked={grandChild.hasPermission}
                                    onChange={(event) =>
                                      handleCheckPermissions(
                                        grandChild.environment,
                                        grandChild.menu,
                                        grandChild.name,
                                        event.target.checked,
                                      )
                                    }
                                  /> */}
                                  <label
                                    htmlFor={`permission-${grandChild.name}`}
                                  >
                                    {grandChild.name}
                                  </label>
                                </S.PermissionItem>
                              ))}
                            </S.ExpandGroup>
                          ))}
                        </>
                      )}
                    </li>
                  ))}
              </S.PermissionsList>

              <S.PermissionType>App</S.PermissionType>

              <S.PermissionsList>
                {appPermissions &&
                  appPermissions.map((permission) => (
                    <li key={permission?.name}>
                      {permission?.data?.map((child) => (
                        <S.PermissionItem key={child.name}>
                          <Checkbox
                            checked={child?.hasPermission}
                            onClick={() =>
                              permission &&
                              handleCheckPermissions(
                                child.environment,
                                child.menu,
                                child.name,
                                !child?.hasPermission,
                              )
                            }
                          />
                          {/* <input
                            type="checkbox"
                            id={`permission-${child.name}`}
                            checked={child?.hasPermission}
                            onChange={(event) =>
                              handleCheckPermissions(
                                child.environment,
                                child.menu,
                                child.name,
                                event.target.checked,
                              )
                            }
                          /> */}
                          <label htmlFor={`permission-${child.name}`}>
                            {child?.name}
                          </label>
                        </S.PermissionItem>
                      ))}
                    </li>
                  ))}
              </S.PermissionsList>
            </S.PermissionsWrapper>
          </>
        )}
      </S.PermissionsContent>

      <S.ButtonContainer>
        {permissionMessage && (
          <S.Message error={permissionMessage.includes("Error")}>
            {permissionMessage}
          </S.Message>
        )}

        <Dialog.Close asChild>
          <Button
            variant="ghost"
            title="Cancel"
            isLoading={loadings.updatePermissions}
          />
        </Dialog.Close>
        <Button
          title="Save"
          isLoading={loadings.updatePermissions}
          onClick={() => updateUserPermissions(userId)}
        />
      </S.ButtonContainer>
    </S.Content>
  );
}

import { useEffect, useState } from "react";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SearchInput } from "../../../../components/New/SearchInput";
import { AxiosError } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OrderList, OrderListParams } from "../../../../@types/Orders";
import { OrderStatus, OrderStatusConverter } from "../../../../enums/Orders";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";
import { ColumnDef } from "@tanstack/react-table";
import { CopyContainer } from "../../../../components/TableElements/components/CopyContainer";
import { ConvertStringWithoutValueToDefault } from "../../../../shared/ConvertStringWithoutValueToDefault";
import { format } from "date-fns";
import { Table } from "../../../../components/New/Table";
import { ReduceString } from "../../../../shared/ReduceString";
import { SelectInput } from "../../../../components/SelectInput";
import { Loading } from "../../../../components/New/Loading";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { NoDataAvailableImage } from "../../../../components/NoDataAvailableImage";
import { DefaultRowsPerPage } from "../../../../components/New/Table/constants";

export function Orders() {
  const updatedParams = new URLSearchParams();
  const [ordersSearchParams, setOrdersSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [ordersList, setOrdersList] = useState<OrderListParams>({
    orders: [],
    page: ordersSearchParams.get("page") ?? "1",
    limit: ordersSearchParams.get("limit") ?? DefaultRowsPerPage,
    totalPages: ordersSearchParams.get("totalPages") ?? "0",

    status: ordersSearchParams.get("status") ?? "",
    search: ordersSearchParams.get("search") ?? "",
  });

  const { orders, page, limit, search } = ordersList;

  const fetchOrders = async () => {
    setLoading(true);

    try {
      const { data } = await wrapperRequests(
        routesURL.report.orders.getOrders,
        "GET",
        {
          params: {
            searchQuery: search || undefined,
            processStatus: ordersList.status
              ? [ordersList.status]
              : [
                  "ERROR",
                  "SUCCESS",
                  "UNPROCESSABLE",
                  "CANCELLED",
                  "PARTIALLY_PROCESSED",
                ],
            page,
            limit,
          },
        },
      );

      setOrdersList((state) => ({
        ...state,
        orders: data.orders,
        totalPages: data.totalPages,
      }));
    } catch (error) {
      if (error instanceof AxiosError) {
        throw Error(error.response?.data.message);
      }
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const updateFiltersValue = (key: string, value: string) => {
    setOrdersList((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const updateSearchParams = () => {
    ordersList.search && updatedParams.set("search", ordersList.search);
    ordersList.status && updatedParams.set("status", ordersList.status);

    updatedParams.set("page", "1");
    updatedParams.set("limit", String(limit));

    setOrdersSearchParams(updatedParams);
  };

  const resetParams = () => {
    setOrdersSearchParams({
      limit: DefaultRowsPerPage,
      page: "1",
    });
  };

  const updateRows = (value: number) => {
    setOrdersSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setOrdersSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const navigate = useNavigate();

  const columns: ColumnDef<OrderList>[] = [
    {
      accessorKey: "orderReferenceNumber",
      header: "Order ID",
      size: 140,
      cell: ({ row }) => (
        <CopyContainer stringValue={row.original.orderReferenceNumber} />
      ),
    },
    {
      accessorKey: "storeOrderID",
      header: "Site Order ID",
      size: 140,
      cell: ({ row }) => (
        <CopyContainer stringValue={row.original.storeOrderID} />
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Order Date",
      size: 160,
      cell: ({ row }) =>
        row.original.createdAt &&
        format(
          new Date(row.original.createdAt),
          "MM-dd-yyyy 'at' hh:mmaaaaa'm'",
        ),
    },
    {
      accessorFn: (row) => {
        const { billing, shipping } = row;
        return billing?.firstName || shipping?.firstName;
      },
      header: "Customer Name",
      size: 160,
      cell: ({ row }) => {
        const { billing, shipping } = row.original;
        return billing?.firstName
          ? `${billing.lastName}, ${billing.firstName}`
          : `${shipping?.lastName}, ${shipping?.firstName}`;
      },
    },
    {
      accessorKey: "processMessage",
      header: "Error Message",
      size: 160,
      cell: ({ row }) =>
        ConvertStringWithoutValueToDefault(row.original.processMessage),
    },
    {
      accessorFn: (row) => row.processStatus,
      header: "Status",
      size: 170,
      cell: ({ row }) => {
        const status = row.original.processStatus;
        return (
          <S.StatusTag status={status} title={OrderStatusConverter[status]}>
            {ReduceString(OrderStatusConverter[status], 100)}
          </S.StatusTag>
        );
      },
    },
    {
      accessorKey: "",
      header: "Actions",
      size: 160,
      cell: ({ row }) => (
        <MdOutlineModeEditOutline
          size={24}
          style={{ cursor: "pointer" }}
          title="Edit"
          onClick={() => navigate(`/report/orders/${row.original.id}`)}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <S.Container>
      <PageHeader title="Orders" description="See all processed orders" />
      <S.Content>
        <SearchInput
          disabled={loading}
          title="Search by identifier"
          placeholder="Search by Order ID/Site Order ID/Customer Name"
          value={ordersList.search}
          onChange={(event) => updateFiltersValue("search", event.target.value)}
          onPressEnter={updateSearchParams}
          filterDropdown={{
            disabled: loading,
            selectInput: (
              <>
                <SelectInput
                  placeholder="Select status"
                  value={ordersList.status}
                  variant="resized"
                  options={[
                    { id: "1", value: OrderStatus.SUCCESS, label: "Success" },
                    { id: "2", value: OrderStatus.ERROR, label: "Error" },
                    {
                      id: "3",
                      value: OrderStatus.UNPROCESSABLE,
                      label: "Unprocessable",
                    },
                    {
                      id: "4",
                      value: OrderStatus.PARTIALLY_PROCESSED,
                      label: "Partially Processed",
                    },
                    {
                      id: "5",
                      value: OrderStatus.CANCELLED,
                      label: "Canceled",
                    },
                  ]}
                  onChange={(event) => {
                    updateFiltersValue("status", event.target.value);
                  }}
                />
              </>
            ),
            onFilterClick: updateSearchParams,
            onResetClick: resetParams,
          }}
        />
        {loading && <Loading />}

        {orders.length === 0 && !loading && <NoDataAvailableImage />}

        {orders.length > 0 && !loading && (
          <>
            <Table
              data={orders}
              columns={columns}
              columnToFilter=""
              key={orders.length}
              style={{
                marginTop: "0.75rem",
                marginBottom: "2rem",
                width: "100%",
              }}
              pagination={{
                rowsPerPage: Number(limit),
                selectPage: updatePage,
                selectRowsPerPage: updateRows,
                page: Number(ordersList.page),
                totalPages: Number(ordersList.totalPages),
              }}
            />
          </>
        )}
      </S.Content>
    </S.Container>
  );
}

import { FormEvent, useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import PackageJson from "../../../package.json";
import Logo from "../../assets/images/white-logo.svg";
import { useAuth } from "../../hooks/auth";

import * as Yup from "yup";

import * as S from "./styles";
import { Input } from "../SignIn/components/Input";
import { MdKey } from "react-icons/md";
import { SignInButton } from "../SignIn/components/SignInButton";

export function PinSignIn() {
  const { authError, pinSignIn, isLoading, signOut } = useAuth();

  const [pin, setPin] = useState("");
  const [error, setError] = useState(authError);

  async function handlePinSignIn(event: FormEvent) {
    event.preventDefault();
    setError("");

    if (pin.length < 4)
      return setError("Your PIN must have at least 4 numbers");

    try {
      await pinSignIn(pin);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(err.message);
      }
    }
  }

  const handleLogout = () => {
    signOut();
  };

  useEffect(() => {
    const shopStoraged = localStorage.getItem("@Incycle:shopSelected");
    const parsedShopStoraged = shopStoraged ? JSON.parse(shopStoraged) : "";

    !parsedShopStoraged && signOut();
  }, []);

  return (
    <S.Container>
      <S.Content autoComplete="off" onSubmit={handlePinSignIn}>
        <img src={Logo} alt="logo" />

        <Input
          label="Pin"
          isPassword
          name="password"
          autoComplete="new-password"
          value={pin}
          onChange={(value) => {
            setError("");
            setPin(value.target.value);
          }}
          icon={<MdKey />}
          errorMessage={error}
        />

        <SignInButton
          type="submit"
          title="Login"
          isLoading={isLoading}
          width="100%"
          height="3.5rem"
        />
      </S.Content>

      <S.LogoutContainer>
        <span>Or</span>
        <button onClick={handleLogout}>Logout</button>
      </S.LogoutContainer>

      <S.VersionContainer>
        <p>Version {PackageJson.version}</p>
      </S.VersionContainer>
    </S.Container>
  );
}

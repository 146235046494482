import styled, { css } from "styled-components";

interface PageHeaderProps {
  hasBorder?: boolean;
}

export const PageHeader = styled.header<PageHeaderProps>`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  ${(props) =>
    !props.hasBorder &&
    css`
      border: none;
    `};

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    border-bottom: 0;
  }
`;

export const Title = styled.span`
  font-size: ${(props) => props.theme.fontSize.MD};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["black-925"]};

  margin: 1.5rem 0 0.5rem 0;
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    margin: 0.75rem 0 0.25rem 0;
    font-size: 1.125rem;
  }
`;

export const OrderDetailsTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["black-925"]};
`;

export const AlignTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  margin: 1.5rem 0 0.5rem;
`;

interface DescriptionContainerProps {
  isOrderDetails?: boolean;
}

export const DescriptionContainer = styled.div<DescriptionContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.5rem;

  span {
    max-width: 40.31rem;
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.colors["gray-800"]};
  }

  ${(props) =>
    props.isOrderDetails &&
    css`
      align-items: flex-start;
      flex-direction: column;
    `}

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;

    span {
      width: 100%;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
      margin-bottom: 1rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    width: 100%;
    & > button {
      width: 100%;
      height: 2.5rem;
    }
  }

  button.default-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
    background-color: ${(props) => props.theme.colors.white};

    &:hover {
      border: 1px solid ${(props) => props.theme.colors["gray-900"]};
      transition: border 0.2s;

      svg {
        color: ${(props) => props.theme.colors["gray-900"]};
        transition: color 0.2s;
      }
    }
  }
`;

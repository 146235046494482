import styled from "styled-components";

export const ForgotPasswordContainer = styled.main`
  display: flex;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: ${(props) => props.theme.colors["black-900"]};

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    padding: 0 1.5rem;
  }
`;

export const ForgotPasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 24rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2.5rem;
  cursor: pointer;
`;

import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FaExclamationTriangle } from "react-icons/fa";
import { useContextSelector } from "use-context-selector";
import { CreateUserData, UserSchemaTypes } from "../../../../../@types/User";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { SelectInput } from "../../../../../components/SelectInput";
import { StoresContext } from "../../../../../contexts/StoresContext";
import { useRegisterUsers } from "../../../../../hooks/api/Users/useRegisterUsers";
import { PermissionsModal } from "../components/PermissionsModal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ZodType, z } from "zod";
import { createUserSchema, editUserSchema } from "./schemas";

import { UsersPermissionsContext } from "../../../../../contexts/UsersPermissions";
import { wrapperRequests } from "../../../../../services/api";
import { routesURL } from "../../../../../services/routesUrl";
import * as S from "./styles";
import { Input } from "../../../../../components/New/Input";
import { SwitchInput } from "../../../../../components/Switch/SwitchInput";
import { Table } from "../../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import { SearchData, StoreListTypes } from "../../../../../@types/Stores";
import { SearchInput } from "../../../../../components/New/SearchInput";
import { Checkbox } from "../../../../../components/New/Checkbox";
import { Loading } from "../../../../../components/New/Loading";
import { Button } from "../../../../../components/New/Button";
import { Modal } from "../../../../../components/New/Modal";

export function RegisterUser() {
  const [userDataToEdit, setUserDataToEdit] = useState({} as UserSchemaTypes);
  const [loadingUser, setLoadingUser] = useState(false);
  const [userStoresGranted, setUserStoresGranted] = useState<string[]>([]);
  const [userDefaultStore, setUserDefaultStore] = useState("");
  const [userUsePassword, setUserUsePassword] = useState(false);
  const [registerFormSchema, setRegisterFormSchema] = useState(
    {} as ZodType<any, any, any>,
  );
  const [searchStore, setSearchStore] = useState<SearchData>({
    shopName: null,
    type: null,
    tenantName: null,
    page: 1,
  });

  type RegisterFormData = z.infer<typeof registerFormSchema>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CreateUserData>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: userDataToEdit,
  });

  const {
    allStoresAllTenants,
    allStoresSelectPage,
    allStoresSelectRowsPerPage,
    fetchAllStoresAllTenants,
    storesLoading,
    rowsPerPage,
  } = useContextSelector(StoresContext, (context) => {
    return context;
  });

  const { createUser, registerUserLoading, registerUserError, updateUser } =
    useRegisterUsers();

  const {
    getAllRoles,
    allRoles,
    handleAddAcessNewRole,
    handleRemoveAcessRole,
    userRoles,
    getUserPermissions,
    handleDefaultUserRoles,
    updateUserPermissions,
  } = useContextSelector(UsersPermissionsContext, (context) => {
    return context;
  });

  const navigate = useNavigate();
  const { userId } = useParams();

  const isEditUser = userId;

  async function handleAddUser(data: RegisterFormData) {
    await createUser(data);
  }

  async function handleEditUser(data: RegisterFormData) {
    isEditUser && (await updateUser({ data, isEditUser }));
  }

  async function handleSearchStore() {
    await fetchAllStoresAllTenants(searchStore);
  }

  function handleCheckBoxChange(code: string) {
    const updatedStoresGranted = userStoresGranted.includes(code)
      ? userStoresGranted.filter((item) => item !== code)
      : [...userStoresGranted, code];

    const defaultStoreWithStoreAccess =
      updatedStoresGranted.includes(userDefaultStore);

    if (!defaultStoreWithStoreAccess && userStoresGranted.length === 0) {
      setUserDefaultStore(code);
    } else if (!defaultStoreWithStoreAccess) {
      setUserDefaultStore("");
    }

    setUserStoresGranted(updatedStoresGranted);
  }

  function handleSelectDefaultShop(code: string) {
    if (!userStoresGranted.includes(code)) {
      setUserStoresGranted([...userStoresGranted, code]);
      return setUserDefaultStore(code);
    }

    return setUserDefaultStore(code);
  }

  const storesColumns: ColumnDef<StoreListTypes>[] = [
    {
      accessorKey: "name",
      header: () => (
        <S.StoreNameContainer>
          <span>Store Name</span>
        </S.StoreNameContainer>
      ),
      size: 300,
      cell: (props) => (
        <S.StoreNameContainer>
          <Checkbox
            checked={userStoresGranted.includes(props.row.original.id)}
            onClick={() => handleCheckBoxChange(props.row.original.id)}
          />
          <span>{props.getValue()}</span>
        </S.StoreNameContainer>
      ),
    },
    {
      accessorKey: "code",
      header: "Store type",
      size: 100,
      cell: (props) => (
        <S.StoreTypeContainer>
          <span>Warehouse</span>
        </S.StoreTypeContainer>
      ),
    },
    {
      accessorKey: "active",
      header: "Status",
      size: 90,
      cell: (props) => (
        <S.StatusContainer
          variant={props.row.original.active ? "Active" : "Inactive"}
        >
          <span>{props.row.original.active ? "Active" : "Inactive"}</span>
        </S.StatusContainer>
      ),
    },
    {
      accessorKey: "tenantName",
      header: "Region",
      size: 130,
      cell: (props) => <span>{props.getValue()}</span>,
    },
    {
      accessorKey: "state",
      header: "State",
      size: 100,
      cell: (props) => <span>{props.getValue()}</span>,
    },
    {
      accessorKey: "city",
      header: "City",
      size: 100,
      cell: (props) => <span>{props.getValue()}</span>,
    },
    {
      accessorKey: "id",
      header: "Default Store",
      size: 150,
      cell: (props) => (
        <S.DefaultStoreContainer>
          <Checkbox
            checked={userDefaultStore === props.row.original.id}
            onClick={() => handleSelectDefaultShop(props.row.original.id)}
            style={{ alignSelf: "center" }}
          />
        </S.DefaultStoreContainer>
      ),
    },
  ];

  useEffect(() => {
    fetchAllStoresAllTenants();
  }, [fetchAllStoresAllTenants]);

  useEffect(() => {
    const loadUserToEdit = async () => {
      if (isEditUser) {
        setLoadingUser(true);
        try {
          const response = await wrapperRequests(
            routesURL.settings.users.getUser(isEditUser),
            "GET",
          );

          setUserDataToEdit(response.data);

          const storeId = response.data.shopsGranted.map(({ id }: any) => {
            return id;
          });
          setUserStoresGranted(storeId);
          setUserDefaultStore(response.data?.defaultShop?.id || "");
          setUserUsePassword(response.data?.usePassword ?? false);

          setValue("name", response.data.name);
          setValue("email", response.data.email);
          setValue("defaultShop", response.data?.defaultShop?.id || "");
          setValue("shopsGranted", storeId);
          setValue("usePassword", response.data?.usePassword ?? false);
          setValue("password", "");
          setValue("passwordConfirmation", "");
        } catch (error) {
          toast.error("User does not exist.", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/settings/users");
        } finally {
          setLoadingUser(false);
        }
      } else {
        setValue("defaultShop", "");
        setValue("shopsGranted", []);
        setValue("usePassword", false);
        setValue("password", "");
        setValue("passwordConfirmation", "");
      }
    };
    loadUserToEdit();
  }, [isEditUser, setValue, navigate]);

  useEffect(() => {
    if (isEditUser) {
      return setRegisterFormSchema(
        editUserSchema({
          userDefaultStore,
          userStoresGranted,
          userUsePassword,
        }),
      );
    }
    setRegisterFormSchema(
      createUserSchema({
        userDefaultStore,
        userStoresGranted,
        userUsePassword,
      }),
    );
  }, [isEditUser, userDefaultStore, userStoresGranted, userUsePassword]);

  useEffect(() => {
    getAllRoles();

    if (isEditUser) {
      getUserPermissions(isEditUser);
    }
  }, []);

  useEffect(() => {
    if (allRoles.length && userDataToEdit.roles?.length > 0) {
      const defaultUserRoles = allRoles.filter((role) =>
        userDataToEdit.roles?.find((_role) => _role.id === role.id),
      );

      handleDefaultUserRoles(defaultUserRoles);
    }
  }, [allRoles, userDataToEdit.roles]);

  return (
    <S.Container>
      <PageHeader
        title={isEditUser ? "Edit user" : "Create a new user"}
        description={
          isEditUser
            ? "Edit user information and access"
            : "Create a new user to access the application"
        }
        customBreadcrumbString={
          isEditUser
            ? loadingUser
              ? "Loading..."
              : userDataToEdit.name || "Loading..."
            : "Create a new user"
        }
      />

      <S.Form
        autoComplete="off"
        onSubmit={handleSubmit(isEditUser ? handleEditUser : handleAddUser)}
      >
        <S.UsersTitle>User information</S.UsersTitle>
        <S.InputRowContainer isFirst>
          <Input
            title="Name"
            placeholder="Enter name"
            errorMessage={errors.name?.message}
            {...register("name")}
            width="20rem"
          />

          <SwitchInput
            title="Remote access"
            label={userUsePassword ? "On" : "Off"}
            checked={!!userUsePassword}
            onChange={() => setUserUsePassword(!userUsePassword)}
            width="20rem"
          />
        </S.InputRowContainer>

        <S.InputRowContainer>
          <Input
            title="Email"
            placeholder="Enter email"
            errorMessage={errors?.email?.message}
            {...register("email")}
            width="20rem"
          />

          <Input
            title="Pincode"
            placeholder="Enter pincode"
            errorMessage={errors?.pin?.message}
            {...register("pin")}
            width="20rem"
          />
        </S.InputRowContainer>

        {userUsePassword && (
          <S.InputRowContainer>
            <Input
              title="Password"
              type="password"
              placeholder="Enter password"
              errorMessage={errors?.password?.message}
              {...register("password")}
              width="20rem"
            />

            <Input
              title="Confirm Password"
              type="password"
              placeholder="Enter password"
              errorMessage={errors?.passwordConfirmation?.message}
              {...register("passwordConfirmation")}
              width="20rem"
            />
          </S.InputRowContainer>
        )}

        {errors.defaultShop?.message ||
        errors.shopsGranted?.message ||
        registerUserError ? (
          <S.ErrorContainer>
            <FaExclamationTriangle />
            <label>
              {(registerUserError && registerUserError) ||
                (errors.shopsGranted?.message &&
                  errors.shopsGranted?.message) ||
                (errors.defaultShop?.message && errors.defaultShop?.message)}
            </label>
          </S.ErrorContainer>
        ) : null}

        {isEditUser && (
          <S.PermissionsContainer>
            <div>
              <S.AlignTitle>
                <S.UsersTitle>Roles and Permissions</S.UsersTitle>
                <S.UsersTitleDescription>
                  Assign roles to grant role-based permissions or click the
                  'Edit Permissions' button to specify individual permissions
                  for the user
                </S.UsersTitleDescription>
              </S.AlignTitle>

              <S.RolesAcess>
                <SelectInput
                  title="Roles"
                  variant="resized"
                  onChange={(event) => {
                    handleAddAcessNewRole(
                      allRoles.find((role) => role.name === event.target.value),
                    );
                  }}
                  placeholder="Select an option"
                  options={allRoles.map((role) => ({
                    id: role.id,
                    value: role.name,
                    label: role.name,
                  }))}
                  width="20rem"
                />

                {!!userRoles.length && (
                  <S.AccessGrantedContainer>
                    <S.UsersTitle>Access granted</S.UsersTitle>
                    <ul>
                      {userRoles.map((role) => (
                        <li key={role.id} title={role.name}>
                          <span>{role.name}</span>

                          <IoMdClose
                            style={{ cursor: "pointer" }}
                            size={16}
                            onClick={() => {
                              handleRemoveAcessRole(role);
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </S.AccessGrantedContainer>
                )}
              </S.RolesAcess>
            </div>

            <Modal
              customButtons
              modalContent={<PermissionsModal userId={userId} />}
              title="Edit Permissions"
            >
              <Button
                title="Edit Permissions"
                variant="ghost-gray"
                width="9.1rem"
              />
            </Modal>
          </S.PermissionsContainer>
        )}
      </S.Form>

      <S.AlignTitle style={{ marginBottom: "0.75rem" }}>
        <S.UsersTitle>Stores</S.UsersTitle>
        <S.UsersTitleDescription>
          Select below the stores that this user can access
        </S.UsersTitleDescription>
      </S.AlignTitle>

      <SearchInput
        onPressEnter={() => handleSearchStore()}
        onChange={(e) =>
          setSearchStore((currentState) => {
            return {
              ...currentState,
              shopName: e.target.value,
            };
          })
        }
      />

      {storesLoading && <Loading />}

      {!!allStoresAllTenants.content.length && !storesLoading && (
        <Table
          columns={storesColumns}
          columnToFilter=""
          data={allStoresAllTenants.content}
          key={
            allStoresAllTenants.content.length ||
            userStoresGranted.length ||
            userDefaultStore
          }
          style={{ marginTop: "0.75rem", width: "100%" }}
          pagination={{
            page: allStoresAllTenants.page,
            totalPages: allStoresAllTenants.totalPages,
            rowsPerPage: allStoresAllTenants.rowsPerPage,
            selectPage: allStoresSelectPage,
            selectRowsPerPage: allStoresSelectRowsPerPage,
          }}
        />
      )}

      <S.ButtonsContainer>
        <Button
          isLoading={registerUserLoading}
          title="Cancel"
          variant="ghost"
          onClick={() => navigate("/settings/users")}
        />
        <Button
          title="Save"
          isLoading={registerUserLoading}
          onClick={() =>
            handleSubmit(isEditUser ? handleEditUser : handleAddUser)()
          }
        />
      </S.ButtonsContainer>
    </S.Container>
  );
}

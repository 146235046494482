import * as S from "./styles";
import { useResponsive } from "../../hooks/useResponsive";
import Logo from "../../assets/images/white-logo.svg";
import { useNavigate } from "react-router-dom";
import {
  MdOutlinedFlag,
  MdOutlineHttps,
  MdOutlineInventory,
  MdOutlineKeyboardTab,
  MdStorefront,
  MdTune,
} from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { useAuth } from "../../hooks/auth";
import { permissions } from "../../shared/Permissions";
import PackageJson from "../../../package.json";

export function SideBarMenuMobile() {
  const { isSideBarMenuOpen, changeIsSideBarMenuOpen } = useResponsive();
  const { user, signOut, shopSelected } = useAuth();
  const navigate = useNavigate();

  const handleNavigateToPinSignIn = () => {
    changeIsSideBarMenuOpen();
    signOut(true);
  };

  return (
    <>
      {isSideBarMenuOpen && (
        <S.SideBarMenuMobileOverlay
          isOpen={isSideBarMenuOpen}
          onClick={changeIsSideBarMenuOpen}
        />
      )}
      <S.SideBarMenuMobileContainer isOpen={isSideBarMenuOpen}>
        <img
          src={Logo}
          alt="logo"
          onClick={() => navigate("/")}
          style={{ height: 44, width: 114 }}
        />

        <S.SideBarMenuMobileButton
          variant="hide"
          onClick={changeIsSideBarMenuOpen}
        >
          <MdOutlineKeyboardTab
            size={16}
            style={{
              transform: "rotate(180deg)",
            }}
          />
          Hide Menu
        </S.SideBarMenuMobileButton>

        <S.Separator />

        <S.SideBarMenuMobileButton variant="store">
          <GoLocation size={16} />
          {user &&
            (shopSelected.name.length > 35
              ? `${shopSelected.name.slice(0, 31)}...`
              : shopSelected.name)}
        </S.SideBarMenuMobileButton>

        <S.SideBarMenuMobileLinkButton to="/" onClick={changeIsSideBarMenuOpen}>
          <MdStorefront size={16} />
          Stores
        </S.SideBarMenuMobileLinkButton>

        {user.user.permissions.includes(permissions.menu.inventory) && (
          <S.SideBarMenuMobileLinkButton
            to="/inventory"
            onClick={changeIsSideBarMenuOpen}
          >
            <MdOutlineInventory size={16} />
            Inventory
          </S.SideBarMenuMobileLinkButton>
        )}

        {user.user.permissions.includes(permissions.menu.report) && (
          <S.SideBarMenuMobileLinkButton
            to="/report"
            onClick={changeIsSideBarMenuOpen}
          >
            <MdOutlinedFlag size={16} />
            Report
          </S.SideBarMenuMobileLinkButton>
        )}

        {user.user.permissions.includes(permissions.menu.settings) && (
          <S.SideBarMenuMobileLinkButton
            to="/settings"
            onClick={changeIsSideBarMenuOpen}
          >
            <MdTune size={16} />
            Settings
          </S.SideBarMenuMobileLinkButton>
        )}

        <S.Separator />

        <S.SideBarMenuMobileButton onClick={handleNavigateToPinSignIn}>
          <MdOutlineHttps size={16} />
          Lock Account
        </S.SideBarMenuMobileButton>

        <S.VersionText>Version {PackageJson.version}</S.VersionText>
      </S.SideBarMenuMobileContainer>
    </>
  );
}

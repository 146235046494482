import styled from "styled-components";
import { Drawer } from "vaul";

export const Overlay = styled(Drawer.Overlay)`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.192);
`;

export const Content = styled(Drawer.Content)<{
  width?: string;
  variant?: "filter" | "default";}>`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: ${(props) => props.width || "100%"};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1.5rem;
  outline: none;
`;

export const Trigger = styled(Drawer.Trigger)`
  all: unset;
`;

import styled, { css } from "styled-components";

import { OrderStatus } from "../../../../enums/Orders";

export const Container = styled.main`
  padding: 2rem;
`;

export const Content = styled.section`
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    margin-top: 0;
  }
`;

interface StatusTagProps {
  status?: keyof typeof OrderStatus | "loading";
  loading?: boolean;
}

export const StatusTag = styled.div<StatusTagProps>`
  display: inline-block;
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.colors["black-925"]};

  padding: 4px 12px;
  border-radius: ${(props) => props.theme.borderRadius.XXLG};
  background-color: ${(props) => props.theme.colors["green-550"]};

  ${(props) =>
    (props.status === "CANCELLED" || props.status === "ARCHIVED") &&
    css`
      background-color: ${(props) => props.theme.colors["gray-700"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.status === "ERROR" &&
    css`
      background-color: ${(props) => props.theme.colors["red-475"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    props.status === "NEW" &&
    css`
      background-color: ${(props) => props.theme.colors["cyan-200"]};
      color: ${(props) => props.theme.colors["black-925"]};
    `};

  ${(props) =>
    (props.status === "SUCCESS" || props.status === "FINISHED") &&
    css`
      background-color: ${(props) => props.theme.colors["green-550"]};
    `};

  ${(props) =>
    (props.status === "STARTED" || props.status === "IN_PROGRESS") &&
    css`
      background-color: ${(props) => props.theme.colors["blue-675"]};
    `};

  ${(props) =>
    props.status === "UNPROCESSABLE" &&
    css`
      background-color: ${(props) => props.theme.colors["orange-450"]};
    `};

  ${(props) =>
    props.status === "PARTIALLY_PROCESSED" &&
    css`
      background-color: ${(props) => props.theme.colors["red-425"]};
    `};

  ${(props) =>
    props.status === "loading" &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      height: 2rem;
      color: ${(props) => props.theme.colors["black-925"]};
      background-color: ${(props) => props.theme.colors["gray-600"]};
    `};
`;

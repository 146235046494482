import NoAvailableImage from "../../assets/images/no-data-available.svg";
import * as S from "./styles";

export function NoDataAvailableImage() {
  return (
    <S.Container>
      <S.StyledNoDataAvailableImage
        src={NoAvailableImage}
        alt="No available data image"
      />

      <S.NoDataAvailableText>No data available...</S.NoDataAvailableText>
    </S.Container>
  );
}

import styled from "styled-components";

export const ReportContainer = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;

  padding: 2rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    padding: 1.5rem;
    gap: 1rem;
  }
`;

import styled, { css } from "styled-components";

export const Container = styled.section`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    width: 100%;
    background-color: ${(props) => props.theme.newColors["white-500"]};
    border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    border-radius: 4px;
    border-spacing: 0;
    border-bottom: none;

    tr {
      height: 2rem;
    }

    th,
    td {
      text-align: left;
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.newColors["gray-900"]};
      padding: 0.25rem 0;
      padding-left: 1rem;

      border-bottom: 1px solid ${(props) => props.theme.newColors["gray-500"]};

      @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
        height: 4.5rem;
      }
    }

    th {
      @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
        height: 2.5rem;
      }
    }

    th:last-child,
    td:last-child {
      padding-right: 1rem;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }

    td {
      font-weight: ${(props) => props.theme.fontWeight.Regular};
    }

    th {
      padding-left: 0;
    }
  }
`;

export const StyledTh = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  padding-left: 1rem;
`;

interface StyledTrProps {
  variant?: "collapsible";
}

export const StyledTr = styled.tr<StyledTrProps>``;

export const PaginationContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 0.75rem;
  padding: 1px 0;
`;

export const RowsContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  span {
    color: ${(props) => props.theme.colors["gray-850"]};
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;

export const PagesContainer = styled.section`
  display: flex;
  align-items: center;

  gap: 1rem;

  div {
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-100"]};
    border-radius: 6px;
    padding: 0.25rem 0.75rem;
    height: 2rem;

    span {
      color: ${(props) => props.theme.colors["gray-850"]};
    }
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors["gray-100"]};
  border-radius: 6px;

  height: 2rem;
  width: 2rem;

  &:not(:disabled):hover {
    filter: brightness(0.9);
    transition: filter 0.2s;
  }
`;

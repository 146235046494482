import { AxiosError } from "axios";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createContext } from "use-context-selector";
import { Permissions, Role } from "../@types/Permissions";
import { FlattenPermission, Permission } from "../@types/Role";
import { wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";

interface UsersPermissionsContextType {
  allRoles: Role[];
  permissions: Permissions;
  userRoles: Role[];
  permissionMessage: string;
  loadings: {
    updatePermissions: boolean;
    getUsersPermissions: boolean;
  };
  getAllRoles: () => Promise<void>;
  handleAddAcessNewRole: (newRole?: Role | null) => void;
  handleRemoveAcessRole: (role: Role) => void;
  getUserPermissions: (userId: string) => Promise<void>;
  handleExpandPermissions: (environment: string, menu: string) => void;
  handleCheckPermissions: (
    environment: string,
    menu: string,
    name: string,
    value: boolean,
  ) => void;
  handleCheckOrRemoveAllPermissions: (value: boolean) => void;
  updateUserPermissions: (
    userId?: string,
    fromUpdateUser?: boolean,
  ) => Promise<void>;
  handleDefaultUserRoles: (roles: Role[]) => void;
  updateUserRoles: (userId: string) => Promise<void>;
}

interface UserPermissionsProviderProps {
  children: ReactNode;
}

export const UsersPermissionsContext = createContext(
  {} as UsersPermissionsContextType,
);

export function UserPermissionsProvider({
  children,
}: UserPermissionsProviderProps) {
  const location = useLocation();

  const [allRoles, setAllRoles] = useState<Role[]>([]);

  const [loadings, setLoadings] = useState({
    updatePermissions: false,
    getUsersPermissions: false,
  });

  const [userId, setUserId] = useState("");

  const [userRoles, setUserRoles] = useState<Role[]>([]);

  const [permissionMessage, setPermissionMessage] = useState("");

  const [userPermissions, setUserPermissions] = useState<Permission[]>([]);

  const [permissions, setPermissions] = useState<Permissions>({
    WEB: null,
    APP: null,
  });

  const handleLoadings = (
    loadingType: keyof typeof loadings,
    value: boolean,
  ) => {
    setLoadings((state) => ({
      ...state,
      [loadingType]: value,
    }));
  };

  const getAllRoles = async () => {
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.roles.getAllRoles,
        "GET",
      );

      setAllRoles(data.roles);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserPermissions = async (userId: string) => {
    handleLoadings("getUsersPermissions", true);

    setUserId(userId);

    try {
      const { data } = await wrapperRequests(
        routesURL.settings.users.getUserPermissions(userId),
        "GET",
      );

      handlePermissions(data.permissions);
      setUserPermissions(data.permissions);
    } catch (error) {
      console.error(error);
    } finally {
      handleLoadings("getUsersPermissions", false);
    }
  };

  const updateUserRoles = async (userId = "") => {
    try {
      const roles = userRoles.map((role) => role.id);

      await wrapperRequests(
        routesURL.settings.users.editUserRoles(userId),
        "PUT",
        {
          data: {
            roles,
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserPermissions = async (
    userId = "",
    fromUpdateUser?: boolean,
  ) => {
    handleLoadings("updatePermissions", true);

    try {
      const allPermissions = [
        ...Object.values(permissions.WEB ?? {}),
        ...Object.values(permissions.APP ?? {}),
      ].filter(Boolean) as Permission[];

      const flattenPermissions = (
        permissions: Permission[],
      ): FlattenPermission[] => {
        return permissions.flatMap((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { data, menu, modal, ...flattenedItem } = item;

          const flattenedData = item.data ? flattenPermissions(item.data) : [];

          return [flattenedItem, ...flattenedData];
        });
      };

      const flattenedPermissions = flattenPermissions(allPermissions);

      const { data } = await wrapperRequests(
        routesURL.settings.users.editUserPermissions(userId),
        "POST",
        {
          data: {
            permissions: flattenedPermissions,
          },
        },
      );

      if (!fromUpdateUser) {
        await updateUserRoles(userId);
        setPermissionMessage("Permissions Updated.");
        setTimeout(() => {
          setPermissionMessage("");
        }, 2000);
      } else {
        return data;
      }
    } catch (error) {
      if (!fromUpdateUser) {
        setPermissionMessage("Error updating permissions, please try again.");
        setTimeout(() => {
          setPermissionMessage("");
        }, 2000);
      }

      if (error instanceof AxiosError) {
        return error.response?.status;
      }
    } finally {
      handleLoadings("updatePermissions", false);
    }
  };

  const handleAddAcessNewRole = (newRole?: Role | null) => {
    setUserRoles((state) => {
      const roleExists = state.some((role) => role.name === newRole?.name);

      if (newRole && roleExists) {
        return state.map((role) =>
          role.name === newRole.name ? { ...role, ...newRole } : role,
        );
      } else if (newRole) {
        return [...state, newRole];
      }

      return state;
    });
  };

  const handleDefaultUserRoles = (roles: Role[]) => {
    setUserRoles(roles);
  };

  const handleRemoveAcessRole = (role: Role) => {
    const permissionsToUpdate = new Set();

    setUserRoles((roles) => roles.filter((_role) => _role.name !== role.name));

    const allPermissions = [...userPermissions];

    allPermissions.forEach((permission, index) => {
      if (role.permissions.includes(permission.permissionString)) {
        allPermissions[index] = { ...permission, hasPermission: false };
        permissionsToUpdate.add(permission.menu);
      }
    });

    const menusToUpdate = Array.from(permissionsToUpdate);

    allPermissions.forEach((permission, index) => {
      if (
        menusToUpdate.includes(permission.menu) &&
        permission.parentPermission !== ""
      ) {
        allPermissions[index] = { ...permission, hasPermission: false };
      }
    });

    setUserPermissions(allPermissions);
  };

  const mergeRoleAndUserPermissions = useCallback(() => {
    const userPermissionsUpdated = userPermissions.map((permission) => {
      const hasPermission = userRoles.some((role) =>
        role.permissions.includes(permission.permissionString),
      );

      return {
        ...permission,
        hasPermission: hasPermission ? hasPermission : permission.hasPermission,
      };
    });

    setUserPermissions(userPermissionsUpdated);

    handlePermissions(userPermissionsUpdated);
  }, [userRoles]);

  const handlePermissions = (permissions: Permission[]) => {
    const findChildren = (parentPermission: Permission): Permission[] =>
      permissions
        .filter(
          (permission) =>
            permission.parentPermission === parentPermission.permissionString,
        )
        .map((permission) => ({
          ...permission,
          modal: false,
          data: findChildren(permission),
        }));

    const rootPermissions = permissions.filter(
      (permission) => permission.parentPermission === "",
    );

    const organizedPermissions = rootPermissions.map((permission) => ({
      ...permission,
      modal: false,
      data: findChildren(permission),
    }));

    const { WEB, APP } = {
      WEB: organizedPermissions.filter(
        (permission) => permission.environment === "WEB",
      ),
      APP: organizedPermissions.filter(
        (permission) => permission.environment === "APP",
      ),
    };

    const appPermissions = {
      name: "App Permissions",
      environment: "APP",
      parentPermission: "",
      permissionString: "app_permissions",
      menu: "App",
      hasPermission: false,
      data: APP.map((permissions) => ({
        ...permissions,
        parentPermission: "app_permissions",
      })),
      modal: false,
    };

    setPermissions({
      APP: {
        App: appPermissions,
      },
      WEB: {
        Inventory: findByMenu(WEB, "Inventory"),
        Report: findByMenu(WEB, "Report"),
        Settings: findByMenu(WEB, "Settings"),
      },
    });

    function findByMenu(permissions: Permission[], menu: string) {
      return permissions.find((permission) => permission.menu === menu) ?? null;
    }
  };

  const handleExpandPermissions = (environment: string, menu: string) => {
    setPermissions((state) => {
      const currentEnvironment = state[environment];

      if (currentEnvironment) {
        const currentMenuState = currentEnvironment[menu];

        if (currentMenuState && currentMenuState.data) {
          return {
            ...state,
            [environment]: {
              ...currentEnvironment,
              [menu]: {
                ...currentMenuState,
                modal: !currentMenuState.modal,
              },
            },
          };
        }
      }
      return state;
    });
  };

  const handleCheckPermissions = (
    environment: string,
    menu: string,
    name: string,
    value: boolean,
  ) => {
    setUserPermissions((userPermissions) =>
      userPermissions.map((permission) => {
        const hasPermission = permission.name === name;

        return {
          ...permission,
          hasPermission: hasPermission ? value : permission.hasPermission,
        };
      }),
    );

    setPermissions((state) => {
      const currentEnvironment = state[environment];

      if (currentEnvironment) {
        const currentMenuState = currentEnvironment[menu];

        if (currentMenuState) {
          if (currentMenuState.name === name) {
            return {
              ...state,
              [environment]: {
                ...currentEnvironment,
                [menu]: {
                  ...currentMenuState,
                  hasPermission: value,
                  data: currentMenuState.data
                    ? currentMenuState.data.map((child) => ({
                        ...child,
                        hasPermission: value,
                        data: child.data
                          ? child.data.map((grandChild) => ({
                              ...grandChild,
                              hasPermission: value,
                            }))
                          : [],
                      }))
                    : [],
                },
              },
            };
          } else if (currentMenuState.data) {
            const updatedData = currentMenuState.data.map((child) =>
              child.name === name
                ? {
                    ...child,
                    hasPermission: value,
                    data: child.data
                      ? child.data.map((grandChild) => ({
                          ...grandChild,
                          hasPermission: value,
                        }))
                      : [],
                  }
                : {
                    ...child,
                    data: (child.data || []).map((grandChild) =>
                      grandChild.name === name
                        ? {
                            ...grandChild,
                            hasPermission: value,
                          }
                        : grandChild,
                    ),
                  },
            );

            const updatePermissionsParent = (
              data: Permission[] | undefined,
              name: string,
              value: boolean,
            ): Permission[] | undefined => {
              return data?.map((child) => {
                if (
                  child.data &&
                  child.data.some(
                    (grandChild) =>
                      grandChild.name === name && !child.hasPermission,
                  )
                ) {
                  return {
                    ...child,
                    hasPermission: value,
                    data: updatePermissionsParent(child.data, name, value),
                  };
                }
                return child;
              });
            };

            const hasAnyPermissionChecked =
              updatedData.some(
                (child) =>
                  child.hasPermission ||
                  (child.data?.some((grandChild) => grandChild.hasPermission) ??
                    false),
              ) || currentMenuState.hasPermission;

            const updatedDataWithParentCheck: Permission[] | undefined =
              updatePermissionsParent(updatedData, name, value);

            return {
              ...state,
              [environment]: {
                ...currentEnvironment,
                [menu]: {
                  ...currentMenuState,
                  hasPermission: hasAnyPermissionChecked,
                  data: updatedDataWithParentCheck,
                },
              },
            };
          }
        }
      }

      return state;
    });

    setUserPermissions((userPermissions) =>
      userPermissions.map((permission) => {
        const hasPermission = permission.name === name;

        return {
          ...permission,
          hasPermission: hasPermission ? value : permission.hasPermission,
        };
      }),
    );
  };

  const handleCheckOrRemoveAllPermissions = (value: boolean) => {
    setUserPermissions((userPermissions) =>
      userPermissions.map((permission) => {
        return {
          ...permission,
          hasPermission: value,
        };
      }),
    );

    setPermissions((state) => {
      return {
        ...state,
        WEB: updatePermissions(state.WEB, value),
        APP: updatePermissions(state.APP, value),
      };
    });
  };

  const updatePermissions = (
    permissionsSection: Permissions[keyof Permissions],
    value: boolean,
  ): Permissions[keyof Permissions] => {
    if (permissionsSection) {
      return Object.fromEntries(
        Object.entries(permissionsSection).map(([key, permission]) => [
          key,
          permission
            ? {
                ...permission,
                hasPermission: value,
                data: permission.data?.map((child) => ({
                  ...child,
                  hasPermission: value,
                  data: child.data?.map((grandChild) => ({
                    ...grandChild,
                    hasPermission: value,
                  })),
                })),
              }
            : null,
        ]),
      );
    }
    return null;
  };

  useEffect(() => {
    mergeRoleAndUserPermissions();
  }, [mergeRoleAndUserPermissions, userRoles]);

  useEffect(() => {
    if (location.pathname !== `/settings/users/${userId}`) {
      setUserRoles([]);
    }
  }, [location.pathname, userId]);

  return (
    <UsersPermissionsContext.Provider
      value={{
        getUserPermissions,
        allRoles,
        userRoles,
        getAllRoles,
        handleAddAcessNewRole,
        handleRemoveAcessRole,
        handleExpandPermissions,
        permissions,
        handleCheckPermissions,
        handleCheckOrRemoveAllPermissions,
        updateUserPermissions,
        handleDefaultUserRoles,
        permissionMessage,
        loadings,
        updateUserRoles,
      }}
    >
      {children}
    </UsersPermissionsContext.Provider>
  );
}

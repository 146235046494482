import { useState } from "react";
import { MdContentCopy, MdDone } from "react-icons/md";
import * as S from "./styles";

interface CopyContainerProps {
  stringValue?: string | number;
  extraRecords?: number;
}

export function CopyContainer({
  extraRecords,
  stringValue,
}: CopyContainerProps) {
  const [copyToClipboard, setCopyToClipboard] = useState("");

  const handleCopyInfo = (info?: string) => {
    if (info) {
      navigator.clipboard.writeText(String(info));

      setCopyToClipboard(info);

      setTimeout(() => {
        setCopyToClipboard("");
      }, 2000);
    }
  };

  return (
    <>
      {stringValue ? (
        <S.CopyContainer>
          {stringValue && (
            <S.CaOrderIdSpan>{String(stringValue)}</S.CaOrderIdSpan>
          )}
          <div>
            {copyToClipboard === String(stringValue) ? (
              <MdDone title="Copied" size={16} />
            ) : (
              <MdContentCopy
                title="Copy"
                size={16}
                onClick={() => {
                  handleCopyInfo(String(stringValue));
                }}
              />
            )}
            {extraRecords && <S.CountCircle>+{extraRecords - 1}</S.CountCircle>}
          </div>
        </S.CopyContainer>
      ) : (
        "--"
      )}
    </>
  );
}

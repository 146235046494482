import styled from "styled-components";
import { Button } from "../../../../components/New/Button";

export const SignInButton = styled(Button)<{ height?: string }>`
  margin-top: 2rem;
  height: ${(props) => props.height || "auto"};
  font-weight: ${(props) => props.theme.fontWeight.Medium};
  flex-shrink: 0;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    height: 2.5rem;
    font-size: ${(props) => props.theme.fontSize.XXS};
    margin-top: 1rem;
  }
`;

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState, useMemo } from "react";
import ReactLoading from "react-loading";

import {
  IInventoryItems,
  ItemsParams,
} from "../../../../@types/InventoryItems";
import { Table } from "../../../../components/New/Table";

import * as S from "./styles";
import { CopyContainer } from "../../../../components/TableElements/components/CopyContainer";
import { useAuth } from "../../../../hooks/auth";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { Loading } from "../../../../components/New/Loading";
import { SearchInput } from "../../../../components/New/SearchInput";
import { NoDataAvailableImage } from "../../../../components/NoDataAvailableImage";
import { useMediaQuery } from "usehooks-ts";
import { useTheme } from "styled-components";
import { DefaultRowsPerPage } from "../../../../components/New/Table/constants";

export function ItemSearch() {
  const { shopSelected } = useAuth();
  const navigate = useNavigate();
  const updatedParams = new URLSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ItemsParams>({
    content: [],
    name: searchParams.get("name") ?? "",
    page: searchParams.get("page") ?? "1",
    limit: searchParams.get("limit") ?? DefaultRowsPerPage,
    sortBy: searchParams.get("sortBy") ?? "",
    totalPages: searchParams.get("totalPages") ?? "0",
    tenantName: shopSelected.tenantName,
    search: searchParams.get("search") ?? "",
  });

  const { content, limit, name, page, search, sortBy, tenantName, totalPages } =
    items;

  const fetchItems = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.inventory.itemSearch.getAllItems(shopSelected.code),
        "GET",
        {
          params: {
            tenantName: shopSelected.tenantName,
            page,
            limit,
            search,
            name,
            sortBy,
          },
        },
      );

      setItems((state) => {
        return {
          ...state,
          content: data.content,
          totalPages: data.totalPages,
        };
      });
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const resetParams = () => {
    setSearchParams({
      limit: "5",
      page: "1",
    });
  };

  const updateItemsValue = (key: keyof ItemsParams, value: string) => {
    setItems((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateRows = (value: number) => {
    setSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateSearchParams = () => {
    items.name && updatedParams.set("name", items.name);
    items.search && updatedParams.set("search", items.search);

    updatedParams.set("limit", String(items.limit));
    updatedParams.set("page", "1");

    setSearchParams(updatedParams);
  };

  const handleNavigateToDetails = (id: string, sku: string) => {
    navigate(`${id}`, { state: { itemSku: sku } });
  };

  const { responsiveDesign } = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${responsiveDesign.sm})`);

  const columns: ColumnDef<IInventoryItems>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Item Name",
        size: isMobile ? 220 : 500,
        cell: (props) => (
          <CopyContainer stringValue={props.row.original.name} />
        ),
      },
      {
        accessorKey: "sku",
        header: "SKU",
        cell: (props) => <CopyContainer stringValue={props.row.original.sku} />,
      },
      {
        accessorKey: "upc",
        header: "UPC",
        cell: (props) => <CopyContainer stringValue={props.row.original.upc} />,
      },
      {
        accessorKey: "quantityOnHand",
        header: "QTY",
        cell: (props) => <p>{props.getValue()}</p>,
      },
      {
        accessorKey: "quantityTotal",
        header: "Total QTY",
        cell: (props) => <p>{props.getValue()}</p>,
      },
      {
        accessorKey: "quantitySpecialized",
        header: "Vendor QTY",
        cell: (props) => <p>{props.getValue() ?? 0}</p>,
      },
      {
        accessorKey: "",
        size: 100,
        header: "Actions",
        cell: (props) => (
          <MdOutlineRemoveRedEye
            size={24}
            style={{ cursor: "pointer" }}
            title="Edit"
            color="#323232"
            onClick={() =>
              handleNavigateToDetails(
                props.row.original.id,
                props.row.original.sku ?? props.row.original.upc,
              )
            }
          />
        ),
      },
    ],
    [isMobile],
  );

  return (
    <S.Container>
      <PageHeader
        title="Item Search"
        description={"Search for items in your store's inventory"}
      />
      <S.Content>
        <S.ItemSearchFiltersContainer>
          <SearchInput
            disabled={loading}
            onPressEnter={() => updateSearchParams()}
            onChange={(event) => updateItemsValue("search", event.target.value)}
            placeholder="Enter a SKU, UPC or EAN"
            value={items.search}
          />

          <SearchInput
            disabled={loading}
            onPressEnter={() => updateSearchParams()}
            onChange={(event) => updateItemsValue("name", event.target.value)}
            placeholder="Enter a item name"
            value={items.name}
          />
        </S.ItemSearchFiltersContainer>

        {loading && <Loading />}

        {content.length === 0 && !loading && <NoDataAvailableImage />}

        {content.length > 0 && !loading && (
          <Table
            columns={columns}
            columnToFilter="name"
            data={content}
            style={{
              marginTop: "0.75rem",
              marginBottom: "2rem",
              width: "100%",
            }}
            pagination={{
              rowsPerPage: Number(limit),
              selectPage: updatePage,
              selectRowsPerPage: updateRows,
              page: Number(page),
              totalPages: Number(totalPages),
            }}
          />
        )}
      </S.Content>
    </S.Container>
  );
}

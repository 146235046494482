import { useState } from "react";
import { CheckEmail } from "./CheckEmail";
import { CheckCode } from "./CheckCode";
import { CheckNewPassword } from "./CheckNewPassword";
import { SuccessMessage } from "./SuccessMessage";
import { useNavigate } from "react-router-dom";

import { Logo } from "../components/Logo";

import * as S from "./styles";
import { useResponsive } from "../../../hooks/useResponsive";

export interface ForgotPasswordUserDataProps {
  userData?: {
    email: string;
    code: string;
    password: string;
    passwordConfirmation: string;
  };
  setUserData: React.Dispatch<
    React.SetStateAction<{
      email: string;
      code: string;
      password: string;
      passwordConfirmation: string;
    }>
  >;
}

export function ForgotPassword() {
  const [userData, setUserData] = useState({
    email: "",
    code: "",
    password: "",
    passwordConfirmation: "",
  });

  const { isResponsive } = useResponsive();
  const navigate = useNavigate();

  return (
    <S.ForgotPasswordContainer>
      <S.ForgotPasswordContent>
        <S.ImageContainer
          title="Go to Home"
          onClick={() => navigate("/signin")}
        >
          <Logo {...(isResponsive && { width: "173", height: "64" })} />
        </S.ImageContainer>

        {!userData.email && <CheckEmail setUserData={setUserData} />}
        {userData.email && !userData.code && (
          <CheckCode setUserData={setUserData} userData={userData} />
        )}
        {userData.email && userData.code && !userData.password && (
          <CheckNewPassword setUserData={setUserData} userData={userData} />
        )}
        {userData.email && userData.code && userData.password && (
          <SuccessMessage />
        )}
      </S.ForgotPasswordContent>
    </S.ForgotPasswordContainer>
  );
}

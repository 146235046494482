import { FormEvent, useState } from "react";
import { MdKey } from "react-icons/md";
import { ForgotPasswordUserDataProps } from "..";
import { SignInValidationMessage } from "../../components/SignInValidationMessage";

import { wrapperRequests } from "../../../../services/api";

import { Input } from "../../components/Input";
import { SignInButton } from "../../components/SignInButton";

import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";
import { InputAlign } from "../../styles";

export function CheckNewPassword({
  userData,
  setUserData,
}: ForgotPasswordUserDataProps) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  async function handleUpdatePassword(event: FormEvent) {
    event.preventDefault();
    if (password !== passwordConfirmation) {
      return setError("The passwords did not match");
    }
    if (password.length < 6 || passwordConfirmation.length < 6) {
      return setError("Password must be 6 digits or more");
    }

    try {
      setIsLoading(true);
      await wrapperRequests(
        routesURL.auth.passwordRecoverUpdatePassword,
        "POST",
        {
          data: {
            email: userData?.email,
            recoverycode: userData?.code,
            password: password,
            passwordConfirmation: passwordConfirmation,
          },
        },
      );

      setUserData((currentValue) => {
        return {
          ...currentValue,
          password: password,
          passwordConfirmation: passwordConfirmation,
        };
      });
    } catch (error: any) {
      setError("");
      throw Error(error);
    }
  }

  return (
    <S.CheckNewPasswordContainer
      onSubmit={(event) => handleUpdatePassword(event)}
    >
      <InputAlign>
        <Input
          label="New password"
          icon={<MdKey />}
          name="password"
          value={password}
          onChange={(value) => {
            setPassword(value.target.value);
          }}
          isPassword
        />

        <Input
          label="Password confirmation"
          icon={<MdKey />}
          name="password"
          value={passwordConfirmation}
          onChange={(value) => {
            setPasswordConfirmation(value.target.value);
          }}
          isPassword
        />
      </InputAlign>

      <SignInButton
        type="submit"
        isLoading={isLoading}
        onClick={(event) => handleUpdatePassword(event)}
        title="Change Password"
      />

      {error && <SignInValidationMessage message={error} />}
    </S.CheckNewPasswordContainer>
  );
}

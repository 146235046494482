import { useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { ForgotPasswordUserDataProps } from "..";
import { SignInValidationMessage } from "../../components/SignInValidationMessage";
import { Input } from "../../components/Input";
import { SignInButton } from "../../components/SignInButton";

import { wrapperRequests } from "../../../../services/api";

import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function CheckEmail({ setUserData }: ForgotPasswordUserDataProps) {
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  async function handleCheckEmail() {
    setIsLoading(true);
    setError("");
    const emailRegex = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

    try {
      if (!emailRegex.test(userEmail)) {
        setError("Invalid email");
        throw Error("Invalid email");
      }

      await wrapperRequests(
        routesURL.auth.passwordRecoverValidateEmail,
        "POST",
        {
          data: {
            email: userEmail,
          },
        },
      );


      setUserData((currentValue) => {
        return {
          ...currentValue,
          email: userEmail,
        };
      });
    } catch (error: any) {
      setIsLoading(false);
      error?.response?.status === 400 &&
        setError("Email not found in our database");

      throw Error(error);
    }
  }

  return (
    <S.CheckEmailContainer>
      <span>
        We will help you to recover your access. <br />
        Please enter your username or access email.
      </span>

      <Input
        label='Email'
        type='email'
        name='email'
        value={userEmail}
        icon={<MdOutlineEmail />}
        onChange={(value) => {
          setUserEmail(value.target.value);
        }}
        errorMessage={error}
      />

      <SignInButton
        type='submit'
        isLoading={isLoading}
        onClick={handleCheckEmail}
        title="Recover Password"
      />

      {error && <SignInValidationMessage message={error} />}
    </S.CheckEmailContainer>
  );
}

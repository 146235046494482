import styled from "styled-components";

export const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  gap: 2rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    width: 100%;
  }

  span {
    font-size: ${(props) => props.theme.fontSize.XS};
    color: ${(props) => props.theme.colors["gray-400"]};
    text-align: center;
    width: 90%;
  }
`;

import styled, { css } from "styled-components";

interface InputContainerProps {
  filled: boolean;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  align-self: flex-start;

  font-size: ${(props) => props.theme.fontSize.XXS};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-300"]};

  padding-bottom: 0.5rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  height: 3rem;
  border: 1px solid white;
  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: none;

  background: ${(props) => props.theme.colors["gray-400"]};

  gap: 0.5rem;
  padding: 0 1rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    height: 2rem;
    padding: 0 0.5rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    color: ${(props) => props.theme.colors["gray-750"]};
  }

  &:focus-within {
    outline: 1px solid ${(props) => props.theme.colors["blue-600"]};

    svg {
      color: ${(props) => props.theme.colors["gray-800"]};
    }
  }

  ${(props) =>
    props.filled &&
    css`
      outline: 2px solid ${(props) => props.theme.colors["blue-600"]};

      svg {
        color: ${(props) => props.theme.colors["gray-900"]};
      }
    `};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;

  background: transparent;
  color: ${(props) => props.theme.colors["gray-900"]};
  border: none;

  font-family: ${(props) => props.theme.fontFamily.Sora};

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    line-height: ${(props) => props.theme.lineHeight.LG};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;
  margin-top: 4px;

  color: var(--red);

  svg {
    margin-right: 4px;
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }
`;

import styled, { css } from "styled-components";

interface ButtonContainerProps {
  width?: string;
  height?: string;
  variant?: "ghost" | "ghost-gray" | "destructive";
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  color: ${(props) => props.theme.newColors["white-300"]};
  border: none;
  border-radius: 0.5rem;
  font-family: ${(props) => props.theme.fontFamily.Roboto};

  padding: 0.5rem 0.75rem;
  width: ${(props) => (props.width ? props.width : "7.5rem")};
  height: ${(props) => (props.height ? props.height : "2rem")};

  &:hover {
    background-color: ${(props) => props.theme.newColors["blue-700"]};
    transition: all 0.2s;
  }

  ${(props) =>
    props.variant === "ghost" &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.newColors["blue-500"]};
      color: ${(props) => props.theme.newColors["blue-700"]};

      &:hover {
        background-color: transparent;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        transition: all 0.2s;
      }
    `}

  ${(props) =>
    props.variant === "ghost-gray" &&
    css`
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.newColors["gray-800"]};
      color: ${(props) => props.theme.newColors["gray-800"]};

      &:hover {
        background-color: transparent;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        transition: all 0.2s;
      }
    `}

  ${(props) =>
    props.variant === "destructive" &&
    css`
      background-color: ${(props) => props.theme.colors["red-800"]};
      color: ${(props) => props.theme.newColors["white-300"]};
      border: none;

      &:hover {
        background-color: ${(props) => props.theme.colors["red-700"]};
        transition: all 0.2s;
      }
    `}
`;

import { useState, useEffect, useRef, ReactNode } from "react";
import * as S from "./styles";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { FiSearch, FiX } from "react-icons/fi";
import { useTheme } from "styled-components";
import { Button } from "../Button";
import FilterIcon from "../../../assets/icons/filter-icon.svg";
import { useMediaQuery } from "usehooks-ts";
import { DrawerComponent } from "../Drawer";

interface SearchInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onPressEnter?: () => void;
  handleChangeSearch?: () => void;
  filterDropdown?: {
    selectInput: ReactNode;
    onFilterClick: () => void;
    onResetClick: () => void;
    disabled?: boolean;
  };
}

export function SearchInput({
  filterDropdown,
  handleChangeSearch,
  onPressEnter,
  ...rest
}: SearchInputProps) {
  const { newColors, responsiveDesign } = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${responsiveDesign.sm})`);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  }

  const handleClear = () => {
    if (rest.onChange) {
      rest.onChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    }
    setIsClearing(true);
    inputRef.current?.focus();
  };


  useEffect(() => {
    if (isClearing && (rest.value === "" || rest.value === undefined)) {
      if (onPressEnter) {
        onPressEnter();
      }
      setIsClearing(false);
    }
  }, [rest.value, isClearing, onPressEnter]);

  return (
    <S.Container>
      <S.SearchButton
        disabled={filterDropdown?.disabled}
        onClick={onPressEnter || (() => {})}
        title="Search"
      >
        <FiSearch size={24} color={newColors["gray-900"]} />
      </S.SearchButton>

      <input
        ref={inputRef}
        type="text"
        placeholder="Search"
        onKeyDown={handleKeyDown}
        {...rest}
      />

      {rest.value && rest.onChange && (
        <S.IconButton onClick={handleClear} title="Clear search">
          <FiX size={24} style={{marginRight: "0.4rem"}}  color={newColors["gray-900"]} />
        </S.IconButton>
      )}

      {handleChangeSearch && filterDropdown && (
        <S.IconButton
          disabled={filterDropdown.disabled}
          title="Change search field"
        >
          <S.SwapIcon
            title="Change search field"
            onClick={handleChangeSearch}
          />
        </S.IconButton>
      )}

      {filterDropdown && (
        <>
          {isMobile ? (
            <>
              <S.IconButton
                disabled={filterDropdown.disabled}
                title="Filters"
                onClick={() => setFilterDrawerOpen(true)}
              >
                <img src={FilterIcon} alt="Filter icon" />
              </S.IconButton>
              <DrawerComponent
                open={filterDrawerOpen}
                onOpenChange={setFilterDrawerOpen}
                variant="filter"
                description=""
                triggerContent={null}
                width="100%"
              >
                <S.DrawerContent>
                  <S.Title>Filter by</S.Title>
                  {filterDropdown.selectInput}
                  <S.ButtonContainer>
                    <Button
                      title="Filter"
                      style={{ width: "100%", height: "2.5rem" }}
                      onClick={() => {
                        filterDropdown.onFilterClick();
                        setFilterDrawerOpen(false);
                      }}
                    />
                    <Button
                      title="Reset filters"
                      variant="ghost"
                      style={{ width: "100%", height: "2.5rem" }}
                      onClick={() => {
                        filterDropdown.onResetClick();
                        setFilterDrawerOpen(false);
                      }}
                    />
                  </S.ButtonContainer>
                </S.DrawerContent>
              </DrawerComponent>
            </>
          ) : (
            <DropdownMenu.Root modal={false}>
              <DropdownMenu.Trigger asChild disabled={filterDropdown.disabled}>
                <S.IconButton title="Filters">
                  <img src={FilterIcon} alt="Filter icon" />
                </S.IconButton>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <S.DropdownContent sideOffset={32}>
                  <S.Title>Filter by</S.Title>
                  {filterDropdown.selectInput}
                  <S.ButtonContainer>
                    <Button
                      title="Filter"
                      style={{ width: "100%" }}
                      onClick={filterDropdown.onFilterClick}
                    />
                    <Button
                      title="Reset filters"
                      variant="ghost"
                      style={{ width: "100%" }}
                      onClick={filterDropdown.onResetClick}
                    />
                  </S.ButtonContainer>
                </S.DropdownContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          )}
        </>
      )}
    </S.Container>
  );
}

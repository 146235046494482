import styled from "styled-components";

export const Container = styled.main`
  padding: 2rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    padding: 1.5rem;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 1.5rem;

  gap: 1.5rem;

  button {
    @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
      height: 2.5rem;
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    flex-direction: column;
    align-items: normal;
    gap: 1rem;

    margin-top: 0;
  }
`;

export const FilterTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Regular};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors.black};

  .required {
    color: ${(props) => props.theme.colors["red-200"]};
    margin-left: 0.375rem;
  }
`;

export const SelectInputWrapper = styled.div`
  width: 20rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    width: 100%;
  }
`;

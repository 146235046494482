import styled, { css } from "styled-components";

export const DuplicateItemsContainer = styled.main`
  padding: 2rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    overflow-x: auto;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface StyledTdProps {
  isPendingImport?: boolean;
}

export const StyledTd = styled.td<StyledTdProps>`
  ${(props) =>
    props.isPendingImport &&
    css`
      color: ${(props) => props.theme.newColors["gray-600"]} !important;
      text-decoration: line-through;
    `};
`;

export const GapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SkuUpcEanText = styled.span`
  width: 11.5rem;
`;

export const SystemIDContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 0.5rem;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 20rem;
  width: auto;

  div {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
  }

  span {
    width: 100%;
  }

  svg {
    min-width: 28px;
    min-height: 28px;
    cursor: pointer;
  }
`;

export const DescriptionCollapsibleContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const DuplicateItemsHeaderButtonContainer = styled.div`
  display: flex;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};

  margin-bottom: 1.5rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    margin-bottom: 1rem;
  }
`;

interface DuplicateItemsHeaderButtonProps {
  active?: boolean;
}

export const DuplicateItemsHeaderButton = styled.button<DuplicateItemsHeaderButtonProps>`
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;

  padding: 1rem 2rem;

  color: ${(props) => props.theme.newColors["gray-900"]};

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    padding: 0.75rem 1rem;
    height: 2.5rem;
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.colors["blue-600"]};
      border-bottom: 2px solid ${(props) => props.theme.colors["blue-600"]};
    `};

  &:hover {
    background: ${(props) => props.theme.colors["blue-200"]};
    color: ${(props) => props.theme.colors["blue-600"]};
    transition: all 0.2s;
  }
`;

export const DuplicateItemsFiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin: 2rem 0 2.75rem;
  gap: 1.5rem;

  input {
    width: 22rem;
  }

  div.filters_align {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    gap: 1.5rem;
  }
`;

export const CollapsibleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;

  height: 1.5rem;
  width: 1.5rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    height: 2rem;
    width: 2rem;
  }
`;

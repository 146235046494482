import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

interface ContainerProps {
  isOpen?: boolean;
}

export const SideBarMenuMobileContainer = styled.main<ContainerProps>`
  display: none;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 1.5rem;
    gap: 1rem;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    background-color: ${(props) => props.theme.newColors["black-900"]};
    width: 80vw;
    min-height: 100vh;
    z-index: 1000;

    transition: transform 0.2s ease-in-out;

    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : "translateX(-100%)"};
  }
`;

interface OverlayProps {
  isOpen?: boolean;
}

export const SideBarMenuMobileOverlay = styled.div<OverlayProps>`
  display: none;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;

    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    transition:
      opacity 0.3s ease-in-out,
      visibility 0.3s ease-in-out;
  }
`;

interface SideBarMenuMobileButtonProps {
  variant?: "hide" | "store";
}

export const SideBarMenuMobileButton = styled.button<SideBarMenuMobileButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border: none;
  width: 100%;
  height: 2.5rem;
  background-color: transparent;

  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.newColors["white-300"]};

  padding: 0.25rem 0.75rem;

  ${(props) =>
    props.variant === "hide" &&
    css`
      border: 1px solid ${(props) => props.theme.newColors["gray-400"]};
      border-radius: 0.25rem;
    `};

  ${(props) =>
    props.variant === "store" &&
    css`
      background-color: ${(props) => props.theme.newColors["blue-500"]};
      border-radius: 0.25rem;
    `};
`;

export const SideBarMenuMobileLinkButton = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border: none;
  width: 100%;
  height: 2.5rem;
  background-color: transparent;

  font-size: ${(props) => props.theme.fontSize.XXXS};
  text-decoration: none;
  color: ${(props) => props.theme.newColors["white-300"]};

  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;

  &.active {
    background-color: ${(props) => props.theme.colors["gray-850"]};
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 0.06rem solid ${(props) => props.theme.newColors["gray-900"]};
`;

export const VersionText = styled.span`
  color: ${(props) => props.theme.newColors["white-300"]};
  font-size: ${(props) => props.theme.fontSize.Nano};

  margin-top: auto;
`;

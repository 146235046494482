import { ButtonHTMLAttributes, forwardRef } from "react";
import ReactLoading from "react-loading";
import * as S from "./styles";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  variant?: "ghost" | "ghost-gray" | "destructive";
  width?: string;
  height?: string;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, title, width, height, isLoading, ...rest }, ref) => {
    return (
      <S.ButtonContainer
        ref={ref}
        variant={variant}
        width={width}
        height={height}
        disabled={isLoading}
        {...rest}
      >
        {isLoading && !variant ? (
          <ReactLoading height={25} width={25} type={"spin"} color="#ffffff" />
        ) : (
          title
        )}
      </S.ButtonContainer>
    );
  },
);

Button.displayName = "Button";

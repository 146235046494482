import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { ColumnDef } from "@tanstack/react-table";
import { MdOutlineModeEditOutline } from "react-icons/md";

import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SearchInput } from "../../../../components/New/SearchInput";
import { SelectInput } from "../../../../components/SelectInput";
import { StoresContext } from "../../../../contexts/StoresContext";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import { Table } from "../../../../components/New/Table";
import { Loading } from "../../../../components/New/Loading";
import { StoresListParams, StoreTypes } from "../../../../@types/Stores";

import * as S from "./styles";
import { NoDataAvailableImage } from "../../../../components/NoDataAvailableImage";
import { DefaultRowsPerPage } from "../../../../components/New/Table/constants";

export function Stores() {
  const { fetchAllTenants, allTenants } = useContextSelector(
    StoresContext,
    (context) => {
      return context;
    },
  );
  const navigate = useNavigate();
  const updatedParams = new URLSearchParams();

  const [storeSearchParams, setStoreSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState<StoresListParams>({
    content: [],
    name: storeSearchParams.get("name") ?? "",
    page: storeSearchParams.get("page") ?? "1",
    limit: storeSearchParams.get("limit") ?? DefaultRowsPerPage,
    sortBy: storeSearchParams.get("sortBy") ?? "",
    totalPages: storeSearchParams.get("totalPages") ?? "0",
    shopID: storeSearchParams.get("shopID") ?? "",
    tenantName: storeSearchParams.get("tenantName") ?? "",
  });

  const checkSearchType =
    (stores.name && "name") || (stores.shopID && "shopID") || "name";

  const [searchType, setSearchType] = useState<"name" | "shopID">(
    checkSearchType,
  );

  const { tenantName, page, limit, shopID, name, sortBy, totalPages, content } =
    stores;

  const fetchAllStoresAllTenants = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.stores.getAllStores,
        "GET",
        {
          params: {
            tenantName,
            page,
            limit,
            shopID,
            sortBy,
            name,
          },
        },
      );

      setStores((state) => ({
        ...state,
        content: data.content,
        totalPages: data.totalPages || "0",
      }));
    } catch (error) {
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const updateStoreValue = (key: keyof StoresListParams, value: string) => {
    setStores((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const updateSearchParams = () => {
    name && updatedParams.set("name", name);
    shopID && updatedParams.set("shopID", shopID);
    tenantName && updatedParams.set("tenantName", tenantName);
    sortBy && updatedParams.set("sortBy", sortBy);

    updatedParams.set("limit", String(limit));
    updatedParams.set("page", "1");

    setStoreSearchParams(updatedParams);
  };

  const updateRows = (value: number) => {
    setStoreSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setStoreSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  useEffect(() => {
    fetchAllStoresAllTenants();
    fetchAllTenants();
  }, []);

  const handleChangeSearchType = () => {
    if (searchType === "name") {
      return setSearchType("shopID");
    }

    return setSearchType("name");
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchType === "name") {
      updateStoreValue("shopID", "");
      return updateStoreValue("name", event.target.value);
    }
    updateStoreValue("name", "");
    return updateStoreValue("shopID", event.target.value);
  };

  const columns: ColumnDef<StoreTypes>[] = [
    {
      accessorKey: "code",
      header: "Client ID",
      size: 265,
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "tenantName",
      header: "Region",
      size: 265,
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "name",
      header: "Store Name",
      size: 265,
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "",
      header: "Actions",
      size: 100,
      cell: (props) => (
        <S.IconContainer>
          <MdOutlineModeEditOutline
            size={24}
            style={{ cursor: "pointer" }}
            title="Edit"
            onClick={() =>
              navigate(`/settings/stores/${props.row.original.id}`)
            }
          />
        </S.IconContainer>
      ),
    },
  ];

  return (
    <S.Container>
      <PageHeader
        title="Stores"
        description="Create stores or manage active stores in the system"
      />

      <S.Content>
        <S.FilterContainer>
          <SearchInput
            disabled={loading}
            handleChangeSearch={handleChangeSearchType}
            onPressEnter={() => updateSearchParams()}
            onChange={(event) => handleSearch(event)}
            value={searchType === "name" ? name || "" : shopID || ""}
            filterDropdown={{
              disabled: loading,
              selectInput: (
                <SelectInput
                  placeholder="Select an option"
                  options={allTenants.map((tenantName) => ({
                    id: tenantName.id,
                    value: tenantName.name,
                    label: tenantName.name,
                  }))}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    updateStoreValue("tenantName", event.target.value);
                  }}
                  value={tenantName ?? ""}
                  variant="resized"
                />
              ),
              onFilterClick: () => updateSearchParams(),
              onResetClick: () => updateStoreValue("tenantName", ""),
            }}
            placeholder={
              searchType === "name"
                ? "Search by Store Name"
                : "Search by Shop ID"
            }
          />
        </S.FilterContainer>

        {loading && <Loading />}

        {content.length === 0 && !loading && <NoDataAvailableImage />}

        {!!content.length && !loading && (
          <Table
            columns={columns}
            data={content}
            columnToFilter="name"
            pagination={{
              rowsPerPage: Number(limit),
              selectPage: updatePage,
              selectRowsPerPage: updateRows,
              page: Number(page),
              totalPages: Number(totalPages),
            }}
          />
        )}
      </S.Content>
    </S.Container>
  );
}

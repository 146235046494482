import { useEffect, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SearchInput } from "../../../../components/New/SearchInput";
import { Table } from "../../../../components/New/Table";
import { Role, RoleTypes } from "../../../../@types/Role";
import * as S from "./styles";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../../components/New/Button";
import { Loading } from "../../../../components/New/Loading";
import { Modal } from "../../../../components/New/Modal";
import { DeleteIcon } from "../../../../components/New/Modal/components/DeleteIcon";
import { DeleteModal } from "../../../../components/New/Modal/components/DeleteModal";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { NoDataAvailableImage } from "../../../../components/NoDataAvailableImage";
import { DefaultRowsPerPage } from "../../../../components/New/Table/constants";

export function Roles() {
  const navigate = useNavigate();
  const updatedParams = new URLSearchParams();
  const [roleSearchParams, setRoleSearchParams] = useSearchParams();

  const [roles, setRoles] = useState<RoleTypes>({
    content: [],
    name: roleSearchParams.get("name") ?? "",
    page: roleSearchParams.get("page") ?? "1",
    limit: roleSearchParams.get("limit") ?? DefaultRowsPerPage,
    sortBy: roleSearchParams.get("sortBy") ?? "",
    totalPages: roleSearchParams.get("totalPages") ?? "0",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchType, setSearchType] = useState<"roleName">("roleName");

  const { name, page, limit, sortBy, totalPages } = roles;

  const searchAllRoles = async () => {
    setLoading(true);
    try {
      const params: any = {
        page,
        limit,
        sortBy,
      };

      params.name = name;

      const { data } = await wrapperRequests(
        routesURL.settings.roles.searchRoles,
        "GET",
        { params },
      );
      setRoles((state) => ({
        ...state,
        content: data.content,
        totalPages: data.totalPages || "0",
      }));
    } catch (error) {
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRole = async (id: string) => {
    try {
      await wrapperRequests(routesURL.settings.roles.deleteRole(id), "DELETE");

      toast.success("Role deleted successfully!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setRoles((state) => ({
        ...state,
        content: state.content.filter((role) => role.id !== id),
      }));
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data.message);
      }
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoles((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  };

  const updateSearchParams = () => {
    if (searchType === "roleName" && name) {
      updatedParams.set("name", name);
    }
    sortBy && updatedParams.set("sortBy", sortBy);
    updatedParams.set("limit", String(limit));
    updatedParams.set("page", "1");
    setRoleSearchParams(updatedParams);
  };

  const updateRows = (value: number) => {
    setRoleSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("limit", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setRoleSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const columns: ColumnDef<Role>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: () => <span style={{ fontWeight: 500 }}>Roles</span>,
        size: 200,
        cell: (props) => {
          const roleName = props.getValue() as string;
          return <> {roleName}</>;
        },
      },
      {
        accessorKey: "actions",
        header: () => <span style={{ fontWeight: 500 }}>Actions</span>,
        size: 150,
        cell: (props) => {
          const role = props.row.original;
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <MdOutlineModeEditOutline
                onClick={() =>
                  navigate(`/settings/roles/${role.id}`, {
                    state: { id: role.id },
                  })
                }
                size={24}
                style={{ cursor: "pointer" }}
                title="Edit"
              />
              <Modal
                customButtons
                modalContent={DeleteModal({
                  message: `Are you sure you want to delete role "${props.row.original.name}"?`,
                  onClick: () => handleDeleteRole(role.id),
                  isLoading: loading,
                })}
                title="Role delete"
                width="21.25rem"
              >
                <DeleteIcon title="Delete" />
              </Modal>
            </div>
          );
        },
      },
    ],
    [navigate],
  );

  useEffect(() => {
    searchAllRoles();
  }, []);

  return (
    <S.Container>
      <PageHeader
        title="Roles"
        description="Create and designate roles for each user within the system, specifying their access and permissions"
        customButton
      >
        <Button
          title="New Role"
          onClick={() => navigate("/settings/roles/create")}
        >
          New Role
        </Button>
      </PageHeader>
      <S.Content>
        <SearchInput
          disabled={loading}
          value={name}
          onChange={handleSearch}
          onPressEnter={updateSearchParams}
          placeholder="Search"
        />

        {loading && <Loading />}

        {roles.content.length === 0 && !loading && <NoDataAvailableImage />}

        {roles.content.length > 0 && !loading && (
          <S.StyledTableWrapper>
            <Table
              data={roles.content}
              columns={columns}
              columnToFilter={"roleName"}
              key={roles.content.length}
              style={{
                marginTop: "0.75rem",
                marginBottom: "2rem",
                width: "100%",
              }}
              pagination={{
                rowsPerPage: Number(limit),
                selectPage: updatePage,
                selectRowsPerPage: updateRows,
                page: Number(page),
                totalPages: Number(totalPages),
              }}
            />
          </S.StyledTableWrapper>
        )}
      </S.Content>
    </S.Container>
  );
}

import { useEffect, useState } from "react";
import { MdMenu } from "react-icons/md";

import { useAuth } from "../../../hooks/auth";
import { useLocation } from "react-router-dom";
import { routeName } from "../../../shared/RouteName";
import { HeaderDropdown } from "./components/Dropdown";

import * as S from "./styles";
import { FaCaretDown } from "react-icons/fa";
import { useResponsive } from "../../../hooks/useResponsive";

export default function Header() {
  const [route, setRoute] = useState("");
  const { changeIsSideBarMenuOpen } = useResponsive();
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setRoute(routeName(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <S.HeaderContainer>
        <S.Title>{route}</S.Title>
        <S.UserContainer>
          <S.GreetingsText>
            Hi, <S.UserName>{user.user.name}!</S.UserName>
          </S.GreetingsText>

          <HeaderDropdown>
            <S.HeaderDropdownButton>
              <FaCaretDown />
            </S.HeaderDropdownButton>
          </HeaderDropdown>
        </S.UserContainer>
      </S.HeaderContainer>

      <S.MobileHeaderContainer>
        <S.HamburguerButton onClick={changeIsSideBarMenuOpen}>
          <MdMenu />
        </S.HamburguerButton>

        <S.UserContainer>
          <S.GreetingsText>
            Hi, <S.UserName>{user.user.name}</S.UserName>
          </S.GreetingsText>

          <HeaderDropdown>
            <S.HeaderDropdownButton>
              <FaCaretDown />
            </S.HeaderDropdownButton>
          </HeaderDropdown>
        </S.UserContainer>
      </S.MobileHeaderContainer>
    </>
  );
}

import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 2.5rem 0;
  margin-top: 1.5rem;
`;

export const StyledNoDataAvailableImage = styled.img`
  height: 12rem;
  width: 16.5rem;
`;

export const NoDataAvailableText = styled.span`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Medium};
  color: ${(props) => props.theme.newColors["gray-700"]};

  margin-top: 0.5rem;
`;

import styled from "styled-components";

export const Container = styled.main`
  flex: 1;
`;

export const Content = styled.div`
  padding: 2rem;
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    padding: 1.5rem;
  }
`;

export const PersonalInformation = styled.section`
  max-width: 20rem;
  margin-bottom: 1.5rem;

  & > h2 {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.SM};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-600"]};
    line-height: ${(props) => props.theme.lineHeight.Default};

    @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
      font-size: ${(props) => props.theme.fontSize.XS};
    }
  }
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    max-width: 100%;
  }
`;

export const UserDataContainer = styled.div`
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  & > p {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    color: ${(props) => props.theme.colors["black-925"]};
    margin-bottom: -0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    margin: 0.75rem 0;
  }
`;

export const LoginSecurity = styled.section`
  max-width: 20rem;
  margin: 1.5rem 0;

  & > h2 {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.SM};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors["black-600"]};
    line-height: ${(props) => props.theme.lineHeight.Default};

    @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
      font-size: ${(props) => props.theme.fontSize.XS};
    }
  }
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    max-width: 100%;
  }
`;

export const UpdatePasswordContainer = styled.div`
  margin-top: 2rem;
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  & > p {
    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Regular};
    color: ${(props) => props.theme.colors["black-925"]};
    margin-bottom: -0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    margin: 0.75rem 0;
  }
`;

export const WrapperPin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  align-self: stretch;
  min-height: 4.813rem;

  & > span {
    font-size: 0.875rem;
  }
`;

interface WrapperChangePasswordProps {
  hasError?: boolean;
}

export const WrapperChangePassword = styled.div<WrapperChangePasswordProps>`
  display: grid;
  gap: 1.5rem;

  margin-top: ${({ hasError }) => (hasError ? "1rem" : "0rem")};

  & > .form_update_pin {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
  }

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    & > .form_update_pin {
      flex-direction: column;
    }

    & > .form_update_pin button {
      width: 100%;
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    padding: 1.5rem;
  }
`;

export const Content = styled.section`
  margin-top: 1.5rem;
  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    margin-top: 0;
  }
`;

export const ItemSearchFiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;

  gap: 1rem;

  div.filters_align {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    gap: 1.5rem;
  }
  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

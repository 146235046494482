import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors["black-900"]};

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    padding: 0 1.5rem;
  }
`;

export const Content = styled.form`
  display: flex;

  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 24rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    max-width: 100%;
  }

  img {
    width: 7.8rem;
    height: 3rem;

    margin-bottom: 2rem;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    width: 10.8rem;
    height: 4rem;

    margin-bottom: 2.5rem;
  }
`;

export const InputAlign = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 100%;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    gap: 1rem;
  }
`;

export const Title = styled.label`
  align-self: flex-start;

  font-size: ${(props) => props.theme.fontSize.XXS};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-300"]};

  padding-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

interface InputContainerProps {
  filled: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  border: 1px solid white;
  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: none;

  background: ${(props) => props.theme.colors["gray-400"]};

  gap: 0.5rem;
  padding: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    color: ${(props) => props.theme.colors["gray-750"]};
  }

  &:focus-within {
    outline: 1px solid ${(props) => props.theme.colors["blue-600"]};

    svg {
      color: ${(props) => props.theme.colors["gray-800"]};
    }
  }

  ${(props) =>
    props.filled &&
    css`
      outline: 2px solid ${(props) => props.theme.colors["blue-600"]};

      svg {
        color: ${(props) => props.theme.colors["gray-900"]};
      }
    `};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;

  background: transparent;
  color: ${(props) => props.theme.colors["gray-900"]};
  border: none;

  font-family: ${(props) => props.theme.fontFamily.Sora};
`;

export const ForgotPasswordButton = styled(NavLink)`
  margin-top: 2.5rem;
  color: ${(props) => props.theme.colors["white"]};

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    padding: 0.75rem;
  }

  &:hover {
    filter: brightness(0.7);
    transition: filter 0.2s;
  }
`;

export const VersionContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  p {
    font-style: italic;
    text-align: center;

    color: ${(props) => props.theme.colors["gray-700"]};
  }
`;

import styled from "styled-components";

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      height: 16px;
      width: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const CaOrderIdSpan = styled.span`
  flex: 1;
  display: flex;
  align-items: center;

  word-break: break-all;
`;

export const CountCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: ${(props) => props.theme.colors["green-450"]};
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fontSize.Nano};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
`;

import styled, { css } from "styled-components";

interface LayoutContainerProps {
  isResponsive: boolean;
}

export const LayoutContainer = styled.div<LayoutContainerProps>`
  display: flex;

  margin-left: var(--sideBarWidth);

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    margin-left: 0;
  }

  ${(props) =>
    props.isResponsive &&
    css`
      margin-left: var(--sideBarWidthResponsive);
    `};
`;

export const ContentContainer = styled.main`
  flex: 1;
  min-width: 0; 
`;

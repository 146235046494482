import styled from "styled-components";

export const SettingsContainer = styled.main`
  flex: 1;
  padding: 2rem;
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    padding: 1.5rem;
  }
`;

export const SettingsCardButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1.5rem;
`;

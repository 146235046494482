import styled, { css } from "styled-components";

export const CheckEmailContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    font-size: ${(props) => props.theme.fontSize.XXS};
    color: ${(props) => props.theme.colors["gray-400"]};
    text-align: center;

    margin-bottom: 2.5rem;
  }
`;

interface ForgotPasswordConfirmationProps {
  type: "success" | "error";
}

export const ForgotPasswordConfirmation = styled.div<ForgotPasswordConfirmationProps>`
  display: flex;
  align-items: center;

  padding-left: 2rem;

  width: 100%;
  height: 56px;

  background-color: ${(props) => props.theme.colors["green-650"]};
  text-decoration: none;

  border: none;
  border-radius: ${(props) => props.theme.borderRadius.SMM};

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors.white};

  margin-top: 2rem;

  svg {
    margin-right: 0.5rem;
  }

  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${(props) => props.theme.colors["red-450"]};
    `};
`;

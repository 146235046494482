import { useState } from "react";
import { MdOutlinePassword } from "react-icons/md";
import { ForgotPasswordUserDataProps } from "..";
import { SignInValidationMessage } from "../../components/SignInValidationMessage";
import { Input } from "../../components/Input";
import { SignInButton } from "../../components/SignInButton";

import { wrapperRequests } from "../../../../services/api";

import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";

export function CheckCode({
  userData,
  setUserData,
}: ForgotPasswordUserDataProps) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleCheckCode() {
    setIsLoading(true);
    setError("");

    try {
      await wrapperRequests(
        routesURL.auth.passwordRecoverValidateCode,
        "POST",
        {
          data: {
            email: userData?.email,
            recoverycode: code,
          },
        },
      );

      setUserData((currentValue) => {
        return {
          ...currentValue,
          code: code,
        };
      });
    } catch (error: any) {
      setIsLoading(false);
      setError("Enclosed code is wrong");
      throw Error(error);
    }
  }

  return (
    <S.CheckCodeContainer>
      <S.Description>Please enter the code you received in your email.</S.Description>
      <Input
        label="Code"
        icon={<MdOutlinePassword />}
        type="number"
        value={code}
        onChange={(value) => {
          setCode(value.target.value);
        }}
      />

      <SignInButton
        type='submit'
        isLoading={isLoading}
        onClick={handleCheckCode}
        onSubmit={handleCheckCode}
        title="Confirm code"
      />

      {error && <SignInValidationMessage message={error} />}
    </S.CheckCodeContainer>
  );
}

import { useNavigate } from "react-router-dom";
import CheckForgotPassword from "../../../../assets/images/forgot-password-check.png";

import * as S from "./styles";
import { SignInButton } from "../../components/SignInButton";

export function SuccessMessage() {
  const navigate = useNavigate();

  return (
    <S.SuccessMessageContainer>
      <span>
        Your password has been successfully changed, you can now login by
        clicking the button below:
      </span>
      <img
        src={CheckForgotPassword}
        alt="check-confirmation"
        width="120px"
        height="120px"
      />

      <SignInButton
        onClick={() => navigate("/signin")}
        type="submit"
        title="Login"
      />
    </S.SuccessMessageContainer>
  );
}

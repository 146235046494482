import { format, parseISO } from "date-fns";
import { MdDoDisturb } from "react-icons/md";
import ReactLoading from "react-loading";
import { ProductList } from "../../../../../../@types/Orders";
import { ConfirmationModal } from "../../../../../../components/ConfirmationModal";
import {
  OrderItemProcessStatusConverter,
  OrderStatus,
} from "../../../../../../enums/Orders";
import { colors } from "../../../../../../styles/themes/default/foundations/colors";
import { OrdersAccordion } from "../OrdersAccordion";
import * as S from "./styles";
import { useContextSelector } from "use-context-selector";
import { OrdersContext } from "../../../../../../contexts/OrdersContext";
import {
  ActionButton,
  ActionsContainer,
  ActivityTextArea,
  BillingText,
  GridContainer,
  NotesContainer,
  NotesContainerAlign,
  NumberOfProducts,
  ProductListsTitle,
  ShipmentsContainer,
  StatusContainer,
  SumaryContentColumn,
  SumaryData,
  SumaryDataTitle,
} from "../OrderDetailsComplete/styles";
import { Table } from "../../../../../../components/New/Table";
import { ColumnDef } from "@tanstack/react-table";
import MoneyBack from "../../../../../../assets/icons/money-back.svg";

export function OrderDetailsError() {
  const { loading, orderDetails, cancelProductListLineItem } =
    useContextSelector(OrdersContext, (context) => {
      return context;
    });

  const {
    createdAt,
    requestedShippingCarrier,
    requestedShippingClass,
    privateNotes,
    publicNotes,
  } = orderDetails;

  const shipmentsColumns: ColumnDef<ProductList>[] = [
    {
      accessorFn: (row) => row.product?.sku || "",
      header: "SKU",
      size: 130,
      cell: (props) => <BillingText>{props.getValue()}</BillingText>,
    },
    {
      accessorKey: "title",
      header: "Title",
      size: 332,
      cell: (props) => <BillingText>{props.getValue()}</BillingText>,
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      size: 100,
      cell: (props) => <BillingText>{props.getValue()}</BillingText>,
    },
    {
      accessorKey: "processStatus",
      header: "Status",
      size: 150,
      cell: (props) => (
        <StatusContainer variant={props.row.original.processStatus}>
          {OrderItemProcessStatusConverter[props.row.original.processStatus]}
        </StatusContainer>
      ),
    },
    {
      accessorKey: "",
      header: "Actions",
      size: 70,
      cell: (props) => (
        <ActionsContainer>
          <ConfirmationModal
            title={"Are you sure about canceling this order line?"}
            description={
              "This action is irreversible. It will only cancel the Shipstation Line Item and will not affect anything in Lightspeed"
            }
            variant="delete"
            confirmFunction={() =>
              cancelProductListLineItem(
                orderDetails.id,
                props.row.original.id,
                orderDetails,
              )
            }
          >
            <ActionButton
              title="Cancel item"
              disabled={
                props.row.original.processStatus === "CANCELLED" ||
                loading.cancelItem ||
                orderDetails.sourceMarketplace !== "SBC"
              }
            >
              {loading.cancelItem ? (
                <ReactLoading
                  height={20}
                  width={20}
                  type={"spin"}
                  color={colors["gray-800"]}
                />
              ) : (
                <MdDoDisturb size={20} />
              )}
            </ActionButton>
          </ConfirmationModal>
          <ActionButton title="Refund item" disabled>
            <img src={MoneyBack} alt="" />
          </ActionButton>
        </ActionsContainer>
      ),
    },
  ];

  return (
    <>
      {orderDetails.processStatus === OrderStatus.ERROR &&
        orderDetails.processMessage && (
          <S.ErrorMessageContainer>
            <S.ErrorMessage>{orderDetails.processMessage}</S.ErrorMessage>
          </S.ErrorMessageContainer>
        )}
      <OrdersAccordion title="Summary">
        <GridContainer>
          <SumaryContentColumn>
            <div>
              <SumaryDataTitle>Line items:</SumaryDataTitle>
              <SumaryData>{orderDetails?.items?.length}</SumaryData>
            </div>

            <div>
              <SumaryDataTitle>Buyer:</SumaryDataTitle>
              <SumaryData>
                {orderDetails?.billing?.lastName ??
                  orderDetails?.shipping?.lastName}
                ,{" "}
                {orderDetails?.billing?.firstName ??
                  orderDetails?.shipping?.firstName}
              </SumaryData>
            </div>

            <div>
              <SumaryDataTitle>Created:</SumaryDataTitle>
              <SumaryData>
                {createdAt && format(parseISO(createdAt), "MM/dd/yyyy hh:mm a")}
              </SumaryData>
            </div>

            <div>
              <SumaryDataTitle>Imported:</SumaryDataTitle>
              <SumaryData>
                {orderDetails?.importDateUTC &&
                  format(
                    parseISO(orderDetails?.importDateUTC),
                    "MM/dd/yyyy hh:mm a",
                  )}
              </SumaryData>
            </div>
          </SumaryContentColumn>
          <SumaryContentColumn>
            <div>
              <SumaryDataTitle>ChannelAdvisor Order ID:</SumaryDataTitle>
              <SumaryData>{orderDetails?.orderReferenceNumber}</SumaryData>
            </div>

            <div>
              <SumaryDataTitle>Seller Order ID:</SumaryDataTitle>
              <SumaryData>{orderDetails?.secondaryStoreOrderID}</SumaryData>
            </div>

            <div>
              <SumaryDataTitle>Site Order ID:</SumaryDataTitle>
              <SumaryData>{orderDetails?.storeOrderID}</SumaryData>
            </div>
          </SumaryContentColumn>
          <SumaryContentColumn>
            <div>
              <SumaryDataTitle>Req. Shipping Shopify:</SumaryDataTitle>
              <SumaryData>{requestedShippingCarrier}</SumaryData>
            </div>

            <div>
              <SumaryDataTitle>Carrier/Class:</SumaryDataTitle>
              <SumaryData>{requestedShippingClass}</SumaryData>
            </div>

            <div />
          </SumaryContentColumn>
        </GridContainer>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title={"Product list"}>
        <ShipmentsContainer>
          <NumberOfProducts>
            {orderDetails.items.length} products listed
          </NumberOfProducts>
          {/* <S.TableContainer>
            <TableComponent>
              <thead>
                <tr>
                  <ThComponent withArrows={false}>SKU</ThComponent>
                  <ThComponent withArrows={false}>Title</ThComponent>
                  <ThComponent withArrows={false}>Quantity</ThComponent>
                  <ThComponent withArrows={false}>Status</ThComponent>
                  <ThComponent withArrows={false}>Cancel</ThComponent>
                </tr>
              </thead>
              {orderDetails?.items?.length &&
                orderDetails?.items?.map((item: ProductList) => {
                  return (
                    <tbody key={item.id}>
                      <TrComponent key={item.id}>
                        <TdComponent tdWidth={"15%"}>
                          {item?.product?.sku || ""}
                        </TdComponent>
                        <TdComponent tdWidth={"auto"}>
                          {item?.title || ""}
                        </TdComponent>
                        <TdComponent tdWidth={"10%"}>
                          {item?.quantity}
                        </TdComponent>
                        <TdComponent tdWidth={"10%"}>
                          <StatusContainer variant={item?.processStatus}>
                            {
                              OrderItemProcessStatusConverter[
                                item?.processStatus
                              ]
                            }
                          </StatusContainer>
                        </TdComponent>
                        <TdComponent tdWidth={"10%"}>
                          <ConfirmationModal
                            title={
                              "Are you sure about canceling this order line?"
                            }
                            description={
                              "This action is irreversible. It will only cancel the Shipstation Line Item and will not affect anything in Lightspeed."
                            }
                            variant="delete"
                            confirmFunction={() =>
                              cancelProductListLineItem(
                                orderDetails.id,
                                item.id,
                                orderDetails,
                              )
                            }
                          >
                            <ActionsButton
                              title="Cancel item"
                              disabled={
                                item.processStatus === "CANCELLED" ||
                                loading.cancelItem
                              }
                            >
                              {loading.cancelItem && loading.id === item.id ? (
                                <ReactLoading
                                  height={24}
                                  width={24}
                                  type={"spin"}
                                  color={colors["gray-800"]}
                                />
                              ) : (
                                <MdDoDisturb
                                  color={colors["gray-800"]}
                                  size={32}
                                />
                              )}
                            </ActionsButton>
                          </ConfirmationModal>
                        </TdComponent>
                      </TrComponent>
                    </tbody>
                  );
                })}
            </TableComponent>
          </S.TableContainer> */}

          {orderDetails?.items?.length && (
            <Table
              columns={shipmentsColumns}
              columnToFilter="task"
              data={orderDetails?.items}
              key={orderDetails.processStatus}
              style={{ marginTop: "1.5rem" }}
            />
          )}
        </ShipmentsContainer>
      </OrdersAccordion>

      <S.Separator />

      <OrdersAccordion title="History & Activity">
        <ProductListsTitle
          style={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        >
          Notes
        </ProductListsTitle>

        <NotesContainerAlign>
          <NotesContainer>
            <SumaryData>Public Notes</SumaryData>
            <ActivityTextArea
              style={{ maxWidth: "31rem", maxHeight: "6rem" }}
              defaultValue={publicNotes}
              disabled
            />
          </NotesContainer>

          <NotesContainer>
            <SumaryData>Private Notes</SumaryData>
            <ActivityTextArea
              style={{ maxWidth: "31rem", maxHeight: "6rem" }}
              defaultValue={privateNotes}
              disabled
            />
          </NotesContainer>
        </NotesContainerAlign>
      </OrdersAccordion>
    </>
  );
}

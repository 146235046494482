import styled from "styled-components";

export const InventoryContainer = styled.main`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem;
  gap: 1.5rem;

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    padding: 1.5rem;
    gap: 1rem;
  }
`;

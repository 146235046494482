import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IInventoryItemDetails } from "../../../../../@types/InventoryItems";
import { PageHeader } from "../../../../../components/Headers/PageHeader";
import { ItemDetailsCard } from "../ItemDetailsCard";

import useItemSearch from "../../../../../hooks/api/Inventory/useItemSearch";
import * as S from "./styles";

export function ItemSearchDetails() {
  const [itemDetail, setItemDetail] = useState({} as IInventoryItemDetails);

  const { itemId } = useParams();
  const { fetchItemDetails } = useItemSearch();

  // const numberOfGridCells = Math.floor((screenWidth - 160) / 376);

  const getItemDetails = async (itemId: string) => {
    setItemDetail({} as IInventoryItemDetails);
    const response = await fetchItemDetails(itemId);

    setItemDetail(response);
  };

  useEffect(() => {
    if (itemId) {
      getItemDetails(itemId);
    }
  }, [itemId]);

  return (
    <S.ItemDetailsContainer>
      <PageHeader
        title="Item Details"
        description="View or search the stock of available stores"
        refreshFunction={() => getItemDetails(itemId || "")}
        customBreadcrumbString={
          itemDetail.name ? itemDetail.name : "Item Detail"
        }
      />

      <S.ItemDetailsCardsContainer>
        <ItemDetailsCard title="Identification" data={itemDetail} />
        <ItemDetailsCard title="Pricing" data={itemDetail} />
        <ItemDetailsCard title="Stock" search data={itemDetail} />
        <ItemDetailsCard title="Location" data={itemDetail} />
      </S.ItemDetailsCardsContainer>
    </S.ItemDetailsContainer>
  );
}

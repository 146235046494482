import { z } from "zod";

export const PrinterSchema = z.object({
  id: z.string().optional(),
  serialNumber: z
    .string({ required_error: "Serial Number is required" })
    .min(4, { message: "Serial Number must be 4 digits or more" }),
  activeForProductPrice: z.boolean(),
  tag: z
    .string({ required_error: "Tag is required" })
    .min(4, { message: "Tag must be 4 digits or more" }),
  activeForWarehouseLabel: z.boolean(),
  activeForTransferCompleteLabel: z.boolean(),
  activeForOrderItem: z.boolean(),
  activeForManualTransferCompleteLabel: z.boolean(),
  size: z.enum(
    ["Small - 2.25 x 1.25", "Large - 2.25 x 3", "ExtraLarge - 4 x 6"],
    { required_error: "Size is required" },
  ),
});

export const StoreSchema = z
  .object({
    active: z.boolean(),
    displayName: z
      .string({ required_error: "Display Name is required" })
      .min(4, { message: "Display Name must be 4 digits or more" }),
    channelAdvisorDistributionCenterID: z
      .union([z.string(), z.number()])
      .transform((val) => Number(val))
      .refine((val) => !isNaN(val), { message: "Must be a valid number" }),
    channelAdvisorDistributionCenterCode: z.string({
      required_error: "Channel Advisor Code is required",
    }),
    geolocationLat: z.string().or(z.literal("")).optional(),
    geolocationLon: z.string().or(z.literal("")).optional(),
    shipstationTagIdShipping: z.string({}),
    shipstationTagIdDelivery: z.string({}),
    shipstationTagIdStorePickup: z.string({}),
    shipstationCompanyName: z.string({}),
    zapietLocationIDs: z
      .string()
      .optional()
      .refine(
        (val) =>
          val === undefined ||
          val === "" ||
          val
            .split(",")
            .map((str) => str.trim())
            .every(Boolean),
        { message: "Invalid format, please separate values with commas" },
      ),
  })
  .superRefine((data, ctx) => {
    const latFilled = data.geolocationLat?.trim() !== "";
    const lonFilled = data.geolocationLon?.trim() !== "";

    if (latFilled && !lonFilled) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Latitude and longitude must be filled at the same time",
        path: ["geolocationLon"],
      });
    } else if (!latFilled && lonFilled) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Latitude and longitude must be filled at the same time",
        path: ["geolocationLat"],
      });
    }
  });

export type TPrinterSchema = z.infer<typeof PrinterSchema>;
export type TStoreSchema = z.infer<typeof StoreSchema>;

import { FaCaretDown } from "react-icons/fa";
import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  background-color: ${(props) => props.theme.newColors["white-300"]};
  border-bottom: 1px solid ${(props) => props.theme.newColors["gray-500"]};

  padding: 0 2rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.MD};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 0.65rem;
`;

export const HeaderDropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    color: ${(props) => props.theme.colors["gray-900"]};

    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    height: 2.5rem;
    width: 2.5rem;

    svg {
      height: 1.5rem;
      width: 1.5rem;
      color: ${(props) => props.theme.newColors.white};
    }
  }
`;

export const GreetingsText = styled.label`
  font-size: ${(props) => props.theme.fontSize.XS};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.newColors.white};
  }
`;

export const UserName = styled.label`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};

  text-decoration: underline;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.newColors.white};
  }
`;

export const UserPhoto = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: ${(props) => props.theme.borderRadius.Circular};

  margin: 0 0.5rem 0 1rem;
  cursor: pointer;

  padding: 2px;
  background-color: ${(props) => props.theme.colors.black};
`;

export const MobileHeaderContainer = styled.section`
  display: none;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    height: 3.875rem;
    background-color: ${(props) => props.theme.newColors["black-900"]};
  }
`;

export const HamburguerButton = styled.button`
  display: none;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;

    background-color: transparent;
    border: none;

    svg {
      height: 1.5rem;
      width: 1.5rem;
      color: ${(props) => props.theme.newColors.white};
    }
  }
`;

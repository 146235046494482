import { HTMLAttributes, useState } from "react";
import * as S from "./styles";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FilterInput } from "./components/FilterInput";
import { RxCaretSort } from "react-icons/rx";
import { useResponsive } from "../../../hooks/useResponsive";
import { Modal } from "../Modal";
import { PrintersModalContent } from "../../../pages/Settings/pages/Stores/components/PrintersModalContent";
import { Pagination } from "./components/Pagination";

export interface ColumnFilters {
  id: string;
  value: string;
}

interface TableProps<T> extends HTMLAttributes<HTMLElement> {
  columns: ColumnDef<T>[];
  columnToFilter: string;
  completeData?: any;
  changeData?: (rowIndex: number, columnId: string, value: string) => void;
  data?: T[];
  hasSearch?: boolean;
  hasSort?: boolean;
  hasFirstColumnLine?: boolean;
  modalOnClick?: boolean;
  pagination?: {
    rowsPerPage: number;
    selectPage: (value: number) => void;
    selectRowsPerPage: (value: number) => void;
    page: number;
    totalPages: number;
  };
}

export function Table({
  changeData,
  columns,
  columnToFilter,
  completeData,
  data = [],
  hasSearch = false,
  hasSort = true,
  hasFirstColumnLine = false,
  modalOnClick = false,
  pagination,
  ...rest
}: TableProps<any>) {
  const [tableData, setTableData] = useState(data);
  const [columnFilters, setColumnFilters] = useState<ColumnFilters[]>([]);

  const { isResponsive } = useResponsive();

  const table = useReactTable({
    columns,
    columnResizeMode: "onChange",
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setTableData((prev) =>
          prev.map((row, index) =>
            index === rowIndex
              ? {
                  ...prev[rowIndex],
                  [columnId]: value,
                }
              : row,
          ),
        ),
          changeData && changeData(rowIndex, columnId, value);
      },
    },
    state: {
      columnFilters,
    },
    initialState: {
      pagination: {
        pageSize: pagination?.rowsPerPage || 10,
      },
    },
  });

  const handleSetRowsPerPage = (value: number) => {
    pagination?.selectRowsPerPage(value);
  };

  const handleFirstPage = () => {
    pagination?.selectPage(1);
  };

  const handlePreviousPage = () => {
    if (pagination) {
      const nextPage = pagination.page - 1;
      pagination.selectPage(nextPage);
    }
  };

  const handleNextPage = () => {
    if (pagination) {
      const nextPage = pagination.page + 1;
      pagination.selectPage(nextPage);
    }
  };

  const handleLastPage = () => {
    if (pagination) {
      pagination.selectPage(pagination.totalPages);
    }
  };

  return (
    <>
      {hasSearch && (
        <FilterInput
          columnFilters={columnFilters}
          columnToFilter={columnToFilter}
          setColumnFilters={setColumnFilters}
        />
      )}
      <S.TableContainer
        isResponsive={isResponsive}
        hasFirstColumnLine={hasFirstColumnLine}
        {...rest}
      >
        <S.TableWrapper>
          <table style={{ minWidth: table.getTotalSize() }}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th style={{ width: header.getSize() }} key={header.id}>
                      <S.ThStyled>
                        <S.SortAlign>
                          {typeof header.column.columnDef.header === "function"
                            ? header.column.columnDef.header(header)
                            : header.column.columnDef.header}
                          {hasSort && (
                            <div>
                              <RxCaretSort
                                size={18}
                                style={{ cursor: "pointer" }}
                                title="Sort"
                                onClick={header.column.getToggleSortingHandler()}
                              />
                            </div>
                          )}
                        </S.SortAlign>
                        {/* Disable resize lines
                      <S.Resize
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        isResizing={header.column.getIsResizing()}
                      /> */}
                      </S.ThStyled>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) =>
                modalOnClick ? (
                  <Modal
                    title="Printer Information"
                    modalContent={PrintersModalContent({
                      storeData: completeData,
                      printer: row.original,
                    })}
                    customButtons
                    key={row.id}
                  >
                    <tr key={row.id} style={{ cursor: "pointer" }}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          style={{ width: cell.column.getSize() }}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  </Modal>
                ) : (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        style={{ width: cell.column.getSize() }}
                        key={cell.id}
                      >
                        <S.TdStyled>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </S.TdStyled>
                      </td>
                    ))}
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </S.TableWrapper>
        {pagination && (
          <Pagination
            page={pagination.page}
            totalPages={pagination.totalPages}
            rowsPerPage={pagination.rowsPerPage}
            onFirstPage={handleFirstPage}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
            onLastPage={handleLastPage}
            onChangeRowsPerPage={handleSetRowsPerPage}
          />
        )}
      </S.TableContainer>
    </>
  );
}

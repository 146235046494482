import styled, { css } from "styled-components";

type TableContainerProps = {
  isResponsive: boolean;
  hasFirstColumnLine: boolean;
};

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bcbcbc;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
  }
`;

export const TableContainer = styled.section<TableContainerProps>`
  width: 100%;
  overflow-y: hidden;
  margin-top: 0.75rem;
  margin-bottom: 2rem;

  table {
    width: 100%;
    background-color: ${(props) => props.theme.newColors["white-500"]};
    border: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    border-radius: 0.5rem;
    border-spacing: 0;
    border-bottom: none;

    th,
    td {
      text-align: left;
      font-size: ${(props) => props.theme.fontSize.XXXS};
      font-weight: ${(props) => props.theme.fontWeight.Semibold};
      color: ${(props) => props.theme.newColors["gray-900"]};
      padding: 0.25rem 0;
      padding-left: 1rem;
      border-bottom: 1px solid ${(props) => props.theme.newColors["gray-500"]};
    }

    th:first-child,
    td:first-child {
      ${(props) =>
        props.hasFirstColumnLine &&
        css`
          border-right: 1px solid
            ${(props) => props.theme.newColors["gray-500"]};
        `}
    }

    th:last-child,
    td:last-child {
      padding-right: 1rem;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }

    td {
      font-weight: ${(props) => props.theme.fontWeight.Regular};
    }

    th {
      padding: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

export const ThStyled = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  height: 2rem;
  justify-content: space-between;
  padding-left: 1rem;
  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    min-height: 2.5rem;
  }
`;

export const TdStyled = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    min-height: calc(2rem - 1px);
  }
`;

export const SortAlign = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  div {
    display: flex;
    align-items: center;
  }
`;

type ResizeProps = {
  isResizing: boolean;
};

export const Resize = styled.div<ResizeProps>`
  background-color: ${(props) => props.theme.newColors["gray-500"]};
  height: 32px;
  width: 4px;
  opacity: 0;

  &:hover {
    cursor: ew-resize;
    opacity: 1;
  }
`;

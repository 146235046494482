import styled from "styled-components";

export const CheckCodeContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Description = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXS};
  color: ${(props) => props.theme.colors["gray-400"]};
  text-align: center;
  margin-bottom: 2.5rem;
  `
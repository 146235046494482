import React, {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useState,
} from "react";
import * as S from "./styles";
import { RxCaretSort } from "react-icons/rx";
import { useTheme } from "styled-components";
import { TableWrapper } from "../styles";
import { Pagination } from "../components/Pagination";

interface TableProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  headers: string[];
  sortKeys?: string[];
  onSortClick?: Dispatch<SetStateAction<any>>;
  pagination?: {
    rowsPerPage: number;
    selectPage: (value: number) => void;
    selectRowsPerPage: (value: number) => void;
    page: number;
    totalPages: number;
  };
}

export function CustomTable({
  children,
  headers,
  onSortClick,
  pagination,
  sortKeys,
  ...rest
}: TableProps) {
  const [_, setSortConfig] = useState<{
    key: string;
    order: "asc" | "desc";
  } | null>(null);

  const handleSortClick = (sortKeyPosition: number) => {
    if (onSortClick && sortKeys) {
      const sortKey = sortKeys[sortKeyPosition];

      if (!sortKey) return;

      setSortConfig((prevConfig) => {
        const newOrder =
          prevConfig?.key === sortKey && prevConfig.order === "desc"
            ? "asc"
            : "desc";

        onSortClick((currentValue: any) => {
          return [...currentValue].sort((a, b) => {
            const valueA = a[sortKey];
            const valueB = b[sortKey];

            const normalizedA = Array.isArray(valueA) ? valueA[0] : valueA;
            const normalizedB = Array.isArray(valueB) ? valueB[0] : valueB;

            let comparison = 0;

            if (
              typeof normalizedA === "number" &&
              typeof normalizedB === "number"
            ) {
              comparison = normalizedA - normalizedB;
            } else if (
              typeof normalizedA === "string" &&
              typeof normalizedB === "string"
            ) {
              comparison = normalizedA.localeCompare(normalizedB);
            }

            return newOrder === "asc" ? comparison : -comparison;
          });
        });

        return { key: sortKey, order: newOrder };
      });
    }
  };

  const handleSetRowsPerPage = (value: number) => {
    pagination?.selectRowsPerPage(value);
  };

  const handleFirstPage = () => {
    pagination?.selectPage(1);
  };

  const handlePreviousPage = () => {
    if (pagination) {
      const nextPage = pagination.page - 1;
      pagination.selectPage(nextPage);
    }
  };

  const handleNextPage = () => {
    if (pagination) {
      const nextPage = pagination.page + 1;
      pagination.selectPage(nextPage);
    }
  };

  const handleLastPage = () => {
    if (pagination) {
      pagination.selectPage(pagination.totalPages);
    }
  };

  return (
    <S.Container {...rest}>
      <TableWrapper>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={header}
                  title={
                    header === "RIRC"
                      ? "Returns Internal Reason Code"
                      : undefined
                  }
                >
                  <S.StyledTh>
                    {header}

                    {!!sortKeys && onSortClick && (
                      <RxCaretSort
                        size={18}
                        style={{ cursor: "pointer" }}
                        title="Sort"
                        onClick={() => handleSortClick(index)}
                      />
                    )}
                  </S.StyledTh>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </TableWrapper>
      {pagination && (
        <Pagination
          page={pagination.page}
          totalPages={pagination.totalPages}
          rowsPerPage={pagination.rowsPerPage}
          onFirstPage={handleFirstPage}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onLastPage={handleLastPage}
          onChangeRowsPerPage={handleSetRowsPerPage}
        />
      )}
    </S.Container>
  );
}

interface NewTableRow extends React.HTMLAttributes<HTMLTableRowElement> {
  children: React.ReactNode;
  variant?: "collapsible";
}

export function CustomTableRow({ children, variant, ...rest }: NewTableRow) {
  return (
    <S.StyledTr variant={variant} {...rest}>
      {children}
    </S.StyledTr>
  );
}

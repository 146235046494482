import { MdKeyboardArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";
import * as S from "./styles";

interface BreadcrumbProps {
  customBreadcrumbString?: string;
}

export function Breadcrumb({ customBreadcrumbString }: BreadcrumbProps) {
  const location = useLocation();
  const path = location.pathname;
  const pathnames = path.split("/").filter(Boolean);

  return (
    <S.BreadcrumbContainer>
      {pathnames &&
        pathnames.map((routes, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          return (
            <div key={routes}>
              <S.TextLink end={isLast} to={routeTo}>
                <span>
                  {isLast && customBreadcrumbString
                    ? customBreadcrumbString
                    : routes}
                </span>
              </S.TextLink>
              {!isLast && <MdKeyboardArrowRight size={20} />}
            </div>
          );
        })}
    </S.BreadcrumbContainer>
  );
}

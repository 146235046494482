import { HTMLProps, forwardRef } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import * as S from "./styles";
import { colors } from "../../styles/themes/default/foundations/colors";

interface SelectInputProps
  extends Omit<HTMLProps<HTMLSelectElement>, "options" | "title"> {
  onChange: (e: any) => void;
  variant?:
    | "form"
    | "pagination"
    | "selectStores"
    | "small"
    | "exploreItems"
    | "profile"
    | "roles"
    | "fullWidth"
    | "resized";
  disabled?: boolean;
  error?: string;
  title?: string;
  placeholder?: string;
  options: {
    id: string;
    value: string;
    label: string;
  }[];
  largerSize?: boolean;
  extraLargeSize?: boolean;
  width?: string;
  isSmallErrorMessage?: boolean;
  hasAsterisk?: boolean;
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  (
    {
      title,
      onChange,
      variant,
      disabled,
      isSmallErrorMessage,
      error,
      placeholder,
      options,
      largerSize,
      extraLargeSize,
      width,
      hasAsterisk,
      ...rest
    },
    ref,
  ) => {
    const optionsWithoutDefaultValue = options.filter(
      (option) => option.label.toLowerCase() !== placeholder,
    );

    return (
      <S.SelectInputContainer variant={variant}>
        {title && (
          <S.ErrorAlignContainer>
            <span>
              {title}{" "}
              {hasAsterisk && (
                <label style={{ color: colors["red-900"] }}>*</label>
              )}
            </span>
          </S.ErrorAlignContainer>
        )}
        <S.SelectInputWrapper
          variant={variant}
          disabled={disabled}
          largerSize={largerSize}
          width={width}
          extraLargeSize={extraLargeSize}
          isSmallErrorMessage={isSmallErrorMessage}
        >
          <select disabled={disabled} ref={ref} onChange={onChange} {...rest}>
            {placeholder && (
              <option defaultValue={placeholder} disabled={disabled} value="">
                {placeholder}
              </option>
            )}

            {optionsWithoutDefaultValue
              .filter((option) => option.label !== placeholder)
              .map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
          {variant === "resized" && (
            <MdKeyboardArrowDown className="arrow-icon" />
          )}
          {variant === "selectStores" && (
            <MdKeyboardArrowDown className="arrow-icon" />
          )}

          {error && <label>{error}</label>}
        </S.SelectInputWrapper>
      </S.SelectInputContainer>
    );
  },
);

SelectInput.displayName = "SelectInput";

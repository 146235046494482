import styled from "styled-components";

export const StoreContainer = styled.main`
  height: 100%;
  max-height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.5rem;
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    padding: 1.5rem;
  }
`;
export const ResponsiveTitle = styled.h2`
  display: none;

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    display: flex;
    text-align: start;
    font-weight: 700;
    font-size: 1.125rem;
  }
`;
export const StoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.2rem, 1fr));
  gap: 0.75rem;
`;
export const StoreWrapperText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
`;

export const StoresCardContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem 0.75rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.S};
  border: 1px solid ${(props) => props.theme.colors["gray-100"]};
  height: 9.9rem;
  overflow: hidden;

  button {
    height: 2rem;
    width: 100%;
    gap: 0.5rem;
    margin-top: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid transparent;
    border-radius: ${(props) => props.theme.borderRadius.SM};

    padding: 0.5rem 0.75rem;

    font-family: ${(props) => props.theme.fontFamily.Roboto};
    font-weight: ${(props) => props.theme.fontWeight.Medium};
    color: ${(props) => props.theme.colors["blue-900"]};

    box-sizing: border-box;
    border-radius: var(--Border-Radius-SM, 0.5rem);
    &:disabled {
      color: ${(props) => props.theme.colors["gray-800"]};
    }

    &:not(:disabled):hover {
      border-color: ${(props) => props.theme.colors["blue-450"]};
      transition: all 0.2s;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    margin-top: 0;
    height: 8.75rem;
  }
`;

export const StoreCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;

export const StoreCardInfoContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.375rem;

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
    margin-right: -0.2rem;
    align-self: flex-start;
  }
`;

interface SpanProps {
  isSecond?: boolean;
}

export const StyledSpan = styled.span<SpanProps>`
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-size: ${(props) =>
    props.isSecond ? props.theme.fontSize.XXXS : props.theme.fontSize.XXS};
  font-weight: ${(props) =>
    props.isSecond
      ? props.theme.fontWeight.Regular
      : props.theme.fontWeight.Semibold};

  color: ${(props) =>
    props.isSecond
      ? props.theme.colors["gray-800"]
      : props.theme.colors["gray-850"]};
  text-transform: capitalize;
  text-align: left;
  width: 100%;
`;

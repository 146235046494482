import styled, { css } from "styled-components";

interface SelectInputContainerProps {
  variant?:
    | "form"
    | "pagination"
    | "selectStores"
    | "small"
    | "exploreItems"
    | "profile"
    | "roles"
    | "fullWidth"
    | "resized";
  disabled?: boolean;
  largerSize?: boolean;
  extraLargeSize?: boolean;
  width?: string;
  isSmallErrorMessage?: boolean;
}

export const SelectInputContainer = styled.div<SelectInputContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 0.5rem;

    ${({ variant }) =>
      variant === "form" &&
      css`
        margin-top: 1rem;
      `};

    ${({ variant }) =>
      variant === "resized" &&
      css`
        font-family: ${(props) => props.theme.fontFamily.Roboto};
        font-size: ${(props) => props.theme.fontSize.XXXS};
        font-weight: ${(props) => props.theme.fontWeight.Regular};
        color: ${(props) => props.theme.newColors["black-900"]};

        margin-bottom: 0.25rem;
      `};
  }
`;

export const ErrorAlignContainer = styled.div``;

export const SelectInputWrapper = styled.div<SelectInputContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  max-height: 3rem;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.SM} !important;
  cursor: pointer;

  ${({ variant }) =>
    variant === "fullWidth" &&
    css`
      width: 100%;
    `};

  ${({ variant }) =>
    variant === "form" &&
    css`
      width: 22rem;
      max-height: 3rem;
    `};

  ${({ variant }) =>
    variant === "pagination" &&
    css`
      width: 5rem;
      height: 3rem;
    `};

  ${({ variant }) =>
    variant === "selectStores" &&
    css`
      width: 100%;
      height: 3rem;
      border-radius: ${(props) => props.theme.borderRadius.SM};
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:focus-within {
        border-color: ${(props) => props.theme.colors["blue-600"]};
      }

      select {
        font-size: ${(props) => props.theme.fontSize.XXS} !important;
        font-family: ${(props) => props.theme.fontFamily.Roboto} !important;
        font-weight: ${(props) => props.theme.fontWeight.Regular} !important;
        color: ${(props) => props.theme.colors["gray-850"]} !important;
        padding: 1rem !important;
      }

      .arrow-icon {
        display: block;
        position: absolute;
        right: 1rem;
        pointer-events: none;
        color: ${(props) => props.theme.colors["gray-850"]};
        font-size: ${(props) => props.theme.fontSize.MD};
      }

      &:after {
        display: none;
      }
    `};

  ${({ variant }) =>
    variant === "small" &&
    css`
      width: 12rem;
    `};

  ${({ variant }) =>
    variant === "exploreItems" &&
    css`
      height: 2.5rem;
      width: 12rem;
      border-radius: ${(props) => props.theme.borderRadius.S};
    `};

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["blue-600"]};
    transition: outline 0.2s;
  }

  ${({ variant, disabled }) =>
    variant === "profile" &&
    css`
      width: min(22rem, 100%);
      border-radius: ${(props) => props.theme.borderRadius.SM};
      height: 3rem;
      border: 1px solid ${(props) => props.theme.colors["gray-700"]};

      &:hover {
        outline: transparent;
      }

      ${disabled &&
      css`
        background-color: ${(props) => props.theme.colors["gray-400"]};
        opacity: 1;
        cursor: not-allowed;
      `}
    `};

  ${({ variant }) =>
    variant === "resized" &&
    css`
      width: 100%;
      height: 2rem;
      border-radius: ${(props) => props.theme.borderRadius.SM};
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
        height: 2.375rem;
      }

      &:focus-within {
        border-color: ${(props) => props.theme.colors["blue-600"]};
      }

      select {
        font-size: ${(props) => props.theme.fontSize.XXXS} !important;
        font-weight: ${(props) => props.theme.fontWeight.Regular} !important;
        color: ${(props) => props.theme.colors["gray-850"]} !important;
        padding: 8px !important;
      }

      .arrow-icon {
        display: block;
        position: absolute;
        right: 0.2rem;
        pointer-events: none;
        color: ${(props) => props.theme.colors["gray-850"]};
        font-size: ${(props) => props.theme.fontSize.MD};
      }

      &:after {
        display: none;
      }
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  select {
    font-size: 1rem;
    color: ${(props) => props.theme.colors["gray-800"]};
    padding: 1rem;
    width: 100%;
    max-height: 3.2rem;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    ${({ variant }) =>
      variant === "form" &&
      css`
        font-size: ${(props) => props.theme.fontSize.XXXS};
      `};

    ${({ variant }) =>
      variant === "resized" &&
      css`
        height: 2rem;
      `};

    option {
      cursor: pointer;
      &:hover {
        background-color: ${(props) => props.theme.newColors["gray-300"]};
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid #aaa;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  label {
    font-size: ${(props) =>
      props.isSmallErrorMessage
        ? props.theme.fontSize.Nano
        : props.theme.fontSize.XXXS};
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0.25rem;
    margin-top: ${(props) =>
      props.isSmallErrorMessage ? "0.1rem" : "0.25rem"};
    white-space: nowrap;
    color: ${(props) => props.theme.colors["red-500"]};
  }
`;

import {
  MdOutlinedFlag,
  MdOutlineInventory,
  MdStorefront,
  MdTune,
  MdOutlineHttps,
  MdOutlineKeyboardTab,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/white-logo.svg";
import LogoMin from "../../assets/images/logoMin.png";
import { useAuth } from "../../hooks/auth";
import { permissions } from "../../shared/Permissions";
import PackageJson from "../../../package.json";

import { useResponsive } from "../../hooks/useResponsive";
import * as S from "./styles";

export function SideBarMenu() {
  const navigate = useNavigate();
  const { user, signOut, shopSelected, isLocked } = useAuth();
  const { changeIsResponsive, isResponsive } = useResponsive();

  const handleNavigateToDashboard = () => {
    navigate("/");
  };

  const handleNavigateToPinSignIn = () => {
    signOut(true);
  };

  return (
    <S.MenuContainer responsiveLayout={isResponsive}>
      <S.MenuContent className={isResponsive ? "responsive_layout" : ""}>
        {isResponsive ? (
          <>
            <img
              src={isResponsive ? LogoMin : Logo}
              alt="logo"
              onClick={handleNavigateToDashboard}
              style={{ height: 48, width: 48 }}
            />
          </>
        ) : (
          <>
            <img src={Logo} alt="logo" onClick={handleNavigateToDashboard} />
          </>
        )}

        <S.CentralStoreButton
          className={isResponsive ? "store_button_responsive" : ""}
          to="/"
          title={
            user &&
            (shopSelected.name.length > 23
              ? `${shopSelected.name.slice(0, 16)}...`
              : shopSelected.name)
          }
        >
          <S.LocationIcon
            className={isResponsive ? "location_icon_responsive" : ""}
          />
          {!isResponsive && (
            <span>
              {user &&
                (shopSelected.name.length > 23
                  ? `${shopSelected.name.slice(0, 16)}...`
                  : shopSelected.name)}
            </span>
          )}
        </S.CentralStoreButton>

        <S.MenuOptionsContainer>
          <S.MenuButton
            className={isResponsive ? "menu_button_responsive" : ""}
            to="/"
            title={"Stores"}
          >
            <MdStorefront size={"1rem"} />
            {!isResponsive && <span>Stores</span>}
          </S.MenuButton>

          {user.user.permissions.includes(permissions.menu.inventory) && (
            <S.MenuButton
              className={isResponsive ? "menu_button_responsive" : ""}
              to="/inventory"
              title={"Inventory"}
            >
              <MdOutlineInventory size={"1rem"} />
              {!isResponsive && <span>Inventory</span>}
            </S.MenuButton>
          )}

          {user.user.permissions.includes(permissions.menu.report) && (
            <S.MenuButton
              className={isResponsive ? "menu_button_responsive" : ""}
              to="/report"
              title={"Report"}
            >
              <MdOutlinedFlag size={"1rem"} />
              {!isResponsive && <span>Report</span>}
            </S.MenuButton>
          )}
        </S.MenuOptionsContainer>

        {user.user.permissions.includes(permissions.menu.settings) && (
          <S.MenuButton
            className={isResponsive ? "menu_button_responsive" : ""}
            to="/settings"
            title={"Settings"}
          >
            <MdTune size={"1rem"} />
            {!isResponsive && <span>Settings</span>}
          </S.MenuButton>
        )}

        <S.LockContainer
          className={isResponsive ? "button_responsive" : ""}
          onClick={handleNavigateToPinSignIn}
          title={"Pin Login"}
        >
          <MdOutlineHttps size={"1rem"} />
          {!isResponsive && <p>Lock Account</p>}
        </S.LockContainer>

        <S.HideMenu
          className={isResponsive ? "button_responsive" : ""}
          onClick={() => {
            changeIsResponsive();
          }}
        >
          <MdOutlineKeyboardTab
            size={"1rem"}
            style={{
              transform: isResponsive ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
          {!isResponsive && <p>Hide Menu</p>}
        </S.HideMenu>
        <S.VersionContainer responsiveLayout={isResponsive}>
          <p>
            {isResponsive
              ? `V${PackageJson.version}`
              : `Version ${PackageJson.version}`}
          </p>
        </S.VersionContainer>
      </S.MenuContent>
    </S.MenuContainer>
  );
}

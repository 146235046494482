import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IAuthContextData,
  IUserKongData,
  ShopsGrantedCredentials,
  User,
} from "../@types/Auth";
import { IPermissions } from "../@types/User";
import { decryptToken, encryptToken } from "../services/tokenConfig.service";
import usePermissions from "./api/Users/usePermissions";

import { api, wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shopSelected, setShopSelected] = useState<ShopsGrantedCredentials>(
    {} as ShopsGrantedCredentials,
  );
  const [authError, setAuthError] = useState("");

  const { fetchUserPermissions } = usePermissions();

  const location = useLocation();

  const navigate = useNavigate();

  const onIdle = () => {
    isLocked && !user.user ? signOut() : signOut(true);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    crossTab: true,
    timeout:
      isLocked && !user.user
        ? Number(process.env.REACT_APP_LOGIN_TIMEOUT)
        : Number(process.env.REACT_APP_PIN_TIMEOUT),
    throttle: 500,
  });

  useEffect(() => {
    async function loadTokenStorage(): Promise<void> {
      setInitialLoading(true);
      const shopStoraged = localStorage.getItem("@Incycle:shopSelected");
      const isLockedStoraged = localStorage.getItem("@Incycle:isLocked");
      const userDataStoraged = localStorage.getItem("@Incycle:user");

      getRemainingTime();

      try {
        const userDataDecrypted = decryptToken(userDataStoraged || "");
        const userDataParsed = userDataDecrypted
          ? JSON.parse(userDataDecrypted)
          : "";
        const userData: User = userDataParsed ? JSON.parse(userDataParsed) : "";

        const parsedShopStoraged = shopStoraged ? JSON.parse(shopStoraged) : "";
        const parsedIsLockedStoraged = isLockedStoraged
          ? JSON.parse(isLockedStoraged)
          : false;

        setIsLocked(parsedIsLockedStoraged);

        if (
          userData.accessToken &&
          userData.refreshToken &&
          parsedShopStoraged
        ) {
          api.defaults.headers.common["Authorization"] =
            `${userData.accessToken}`;

          await wrapperRequests(routesURL.auth.verifyToken, "POST");

          setShopSelected(userData?.user?.defaultShop);

          setUser({
            ...userData,
            user: {
              ...userData.user,
              permissions: userData.user.permissions,
            },
          });

          parsedShopStoraged && setShopSelected(parsedShopStoraged);

          navigate(`${location.pathname}${location.search && location.search}`);
        }
      } catch (error) {
        console.log(error);
        signOut();
      } finally {
        setInitialLoading(false);
      }
    }

    loadTokenStorage();
  }, []);

  async function pinSignIn(pin: string) {
    setIsLoading(true);
    setAuthError("");

    const shopStoraged = localStorage.getItem("@Incycle:shopSelected");
    const parsedShopStoraged = shopStoraged ? JSON.parse(shopStoraged) : "";

    try {
      const response = await wrapperRequests<IUserKongData>(
        routesURL.auth.pinSignInKong,
        "POST",
        {
          data: {
            pin: pin,
            shopCode: parsedShopStoraged.code,
            tenantName: parsedShopStoraged.tenantName,
            consumerUsername: "centralizer_website",
          },
        },
      );

      api.defaults.headers.common["Authorization"] =
        `${response.data?.accessToken}`;

      const permissionsResponse = await fetchUserPermissions(
        response.data.user.id,
      );

      const onlyTruePermissions =
        permissionsResponse &&
        permissionsResponse
          .filter(
            (permission: IPermissions) =>
              permission.hasPermission && permission.environment === "WEB",
          )
          .map((permission: IPermissions) => permission.permissionString);

      const userData: User = {
        ...response.data,
        user: {
          ...response.data.user,
          permissions: onlyTruePermissions,
        },
      };

      const encryptedUserData = encryptToken(JSON.stringify(userData));

      localStorage.setItem("@Incycle:user", encryptedUserData);

      setUser(userData);

      setShopSelected(parsedShopStoraged);

      toast.success(`🚴‍♂️ Welcome ${response.data.user.name}!`, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setAuthError("");
      setIsLoading(false);
      navigate("/");
    } catch (err: any) {
      setIsLoading(false);
      setAuthError(err?.response?.data?.message);
    }
  }

  const signIn = useCallback(async ({ email, password }) => {
    setIsLoading(true);
    try {
      const response = await wrapperRequests<IUserKongData>(
        routesURL.auth.signInKong,
        "POST",
        {
          data: {
            email,
            password,
            consumerUsername: "centralizer_website",
          },
        },
      );

      setShopSelected(response.data?.user?.defaultShop);

      localStorage.setItem(
        "@Incycle:shopSelected",
        JSON.stringify(response.data.user.defaultShop),
      );

      api.defaults.headers.common["Authorization"] =
        ` ${response.data?.accessToken}`;

      const permissionsResponse = await fetchUserPermissions(
        response.data.user.id,
      );

      const onlyTruePermissions =
        permissionsResponse &&
        permissionsResponse
          .filter(
            (permission: IPermissions) =>
              permission.hasPermission && permission.environment === "WEB",
          )
          .map((permission: IPermissions) => permission.permissionString);

      const userData: User = {
        ...response.data,
        user: {
          ...response.data.user,
          permissions: onlyTruePermissions,
        },
      };

      const encryptedUserData = encryptToken(JSON.stringify(userData));

      localStorage.setItem("@Incycle:user", encryptedUserData);

      setUser(userData);

      toast.success(`🚴‍♂️ Welcome ${response.data.user.name}!`, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/");
    } catch (error) {
      toast.error("Login error, check your data!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw Error(String(error));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const selectStore = (store: ShopsGrantedCredentials) => {
    setShopSelected(store);

    localStorage.setItem("@Incycle:shopSelected", JSON.stringify(store));
  };

  const signOut = useCallback(async (lock?: boolean) => {
    user?.user &&
      (await wrapperRequests(routesURL.auth.singOut, "POST", {
        data: {
          accessToken: user?.accessToken,
          userId: user?.user?.id,
        },
      }));

    localStorage.removeItem("@Incycle:user");

    const shopStoraged = localStorage.getItem("@Incycle:shopSelected");

    delete api.defaults.headers.common.Authorization;

    if (lock && shopStoraged) {
      localStorage.setItem("@Incycle:isLocked", JSON.stringify(true));
      setUser({} as User);
      setIsLocked(true);
      navigate("/pinSignIn");
    } else {
      localStorage.removeItem("@Incycle:shopSelected");
      localStorage.removeItem("@Incycle:isLocked");

      setUser({} as User);
      setIsLocked(false);
      navigate("/signin");
    }
  }, []);

  function logout() {
    localStorage.removeItem("@Incycle:shopSelected");
    localStorage.removeItem("@Incycle:isLocked");

    setUser({} as User);
    setIsLocked(false);
    navigate("/signin");
  }

  return (
    <AuthContext.Provider
      value={{
        authError,
        isLoading,
        initialLoading,
        isLocked,
        logout,
        pinSignIn,
        shopSelected,
        selectStore,
        setShopSelected,
        signIn,
        signOut,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { AuthProvider, useAuth };

import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.colors["black-900"]};
`;

export const Content = styled.form`
  display: flex;

  align-items: center;
  flex-direction: column;

  width: 24rem;

  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    width: 100%;
    padding: 0 1.5rem;
  }

  img {
    width: 8rem;
    height: 3rem;

    margin-bottom: 2.5rem;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
      width: 10.8rem;
      height: 4rem;

      margin-bottom: 2.5rem;
    }
  }

  div.error_div {
    display: flex;
    justify-content: flex-start;

    width: 100%;
    margin-top: 4px;

    color: var(--red);

    svg {
      margin-right: 4px;
    }
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};

  gap: 0.25rem;
  margin-top: 2.5rem;

  span {
    color: ${(props) => props.theme.colors.white};
  }

  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors["red-500"]};

    font-family: ${(props) => props.theme.fontFamily.Sora};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    text-decoration: underline;
  }
`;

export const VersionContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  p {
    font-style: italic;
    text-align: center;

    color: ${(props) => props.theme.colors["gray-700"]};
  }
`;

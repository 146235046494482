export const newColors = {
  "black-900": "#030303",
  black: "#000000",

  "blue-400": "#51B7E0",
  "blue-500": "#0063F9",
  "blue-700": "#0056C9",

  "green-300": "#5EFBD1",
  "green-500": "#51E05D",
  "green-700": "#1D6E4F",

  "gray-100": "#EFEFF1",
  "gray-200": "#f1f1f1",
  "gray-300": "#EBEBE4",
  "gray-400": "#CDCDCD",
  "gray-500": "#D3D3D3",
  "gray-550": "#AFAFAF",
  "gray-600": "#A1A1A1",
  "gray-700": "#6D6D6D",
  "gray-750": "#5D5C5C",
  "gray-800": "#444444",
  "gray-900": "#323232",
  "gray-950": "#2E2E2E",

  "pink-500": "#FFC0C0",

  "red-500": "#AF2A1A",
  "red-600": "#962215",

  "yellow-500": "#E0E051",

  "white-300": "#F9FAFA",
  "white-500": "#F8F8F8",
  white: "#ffffff",
} as const;

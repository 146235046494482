import { ReactNode, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createContext } from "use-context-selector";
import {
  IStore,
  SearchData,
  StoreListTypes,
  StoreParams,
  TenantType,
} from "../@types/Stores";
import { wrapperRequests } from "../services/api";
import { routesURL } from "../services/routesUrl";
import { useLocation } from "react-router";
import { toastify } from "../shared/Toastify";
import { AxiosError } from "axios";
import { DefaultRowsPerPage } from "../components/New/Table/constants";

interface StoresContextType {
  allStoresAllTenants: {
    content: StoreListTypes[];
    name: string;
    rowsPerPage: number;
    page: number;
    sortBy: string;
    totalDocuments: number;
    totalPages: number;
  };
  allStoresFilterBy: (key: keyof StoreParams, value: string) => void;
  allStoresSelectSortBy: (value: string) => void;
  allStoresSelectPage: (value: number) => void;
  allStoresSelectRowsPerPage: (value: number) => void;
  allStoresSelectReset: () => void;
  allTenants: TenantType[];
  selectedStore: IStore;
  createStoreStep: string;
  fetchAllTenants: () => Promise<void>;
  fetchAllStoresAllTenants: (searchData?: SearchData) => Promise<void>;
  fetchStores: () => Promise<void>;
  fetchStore: (storeId: string) => Promise<IStore>;
  editStore: (data: any) => Promise<void>;
  rowsPerPage: number;
  selectPage: (value: number) => void;
  selectSortBy: (value: string) => void;
  selectRowsPerPage: (value: number) => void;
  setCreateStoreStep: React.Dispatch<React.SetStateAction<string>>;
  setSelectedStore: React.Dispatch<React.SetStateAction<IStore>>;
  storesLoading: boolean;
  stores: StoreListTypes[];
  page: number;
  totalPages: number;
}

interface StoresProviderProps {
  children: ReactNode;
}

export const StoresContext = createContext({} as StoresContextType);

export function StoresProvider({ children }: StoresProviderProps) {
  const [selectedStore, setSelectedStore] = useState({} as IStore);
  const [createStoreStep, setCreateStoreStep] = useState("details");
  const [storesLoading, setStoresLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [allStoresAllTenants, setAllStoresAllTenants] = useState<StoreParams>({
    content: [] as StoreListTypes[],
    name: "",
    tenantName: "",
    rowsPerPage: Number(DefaultRowsPerPage),
    page: 1,
    sortBy: "",
    totalDocuments: 1,
    totalPages: 1,
  });

  const [allTenants, setAllTenants] = useState<TenantType[]>([]);

  const location = useLocation();

  const fetchStores = useCallback(async () => {
    const shopStoraged = localStorage.getItem("@Incycle:shopSelected");
    const parsedShopStoraged = shopStoraged ? JSON.parse(shopStoraged) : "";

    setStoresLoading(true);

    try {
      const response = await wrapperRequests(
        routesURL.settings.stores.getStoresByTenant(
          parsedShopStoraged.tenantName,
        ),
        "GET",
        {
          params: {
            page: String(page ? page : 1),
            limit: String(rowsPerPage ? rowsPerPage : 10),
            sortBy: sortBy ? sortBy : undefined,
          },
        },
      );

      setStores(response.data.content);
      setTotalPages(response.data.totalPages);
      setStoresLoading(false);
    } catch (error) {
      setStoresLoading(false);
      throw new Error(String(error));
    }
  }, [page, rowsPerPage, sortBy]);

  const fetchStore = async (storeId: string) => {
    setStoresLoading(true);
    try {
      const response = await wrapperRequests(
        routesURL.settings.stores.getStore(storeId || ""),
        "GET",
      );
      const data: IStore = response.data;

      setSelectedStore(data);

      return data;
    } catch (error) {
      throw Error(String(error));
    } finally {
      setStoresLoading(false);
    }
  };

  const fetchAllStoresAllTenants = useCallback(
    async (searchData?: SearchData) => {
      setStoresLoading(true);

      try {
        const { data } = await wrapperRequests(
          routesURL.settings.stores.getAllStores,
          "GET",
          {
            params: {
              tenantName:
                searchData?.tenantName || allStoresAllTenants.tenantName,
              page: searchData?.page || allStoresAllTenants.page,
              limit: allStoresAllTenants.rowsPerPage,
              sortBy: allStoresAllTenants.sortBy || undefined,
              name: searchData?.shopName || allStoresAllTenants.name,
            },
          },
        );

        setAllStoresAllTenants((currentValue) => {
          return {
            ...currentValue,
            content: data.content,
            page: data.page,
            totalDocuments: data.totalDocuments,
            totalPages: data.totalPages,
          };
        });
      } catch (error) {
        throw Error(String(error));
      } finally {
        setStoresLoading(false);
      }
    },
    [allStoresAllTenants.rowsPerPage, allStoresAllTenants.page],
  );

  const fetchAllTenants = async () => {
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.stores.getAllTenants,
        "GET",
      );

      setAllTenants(data.content);
    } catch (error) {
      throw Error(String(error));
    }
  };

  const editStore = async (data: any) => {
    setStoresLoading(true);
    try {
      if (selectedStore?.tenantName && selectedStore.code) {
        await wrapperRequests(
          routesURL.settings.stores.editStore(
            selectedStore?.tenantName,
            selectedStore.code,
          ),
          "PUT",
          {
            data: data,
          },
        );
      }

      toast.success("🏬 Store edited successfully!", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setStoresLoading(false);
    } catch (error) {
      setStoresLoading(false);
      if (error instanceof AxiosError && error.response?.data.message) {
        toastify("error", error.response?.data.message, "top-center");
        throw Error(error.response?.data.message);
      }

      toast.error("Store edit error.", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw new Error(String(error));
    }
  };

  function allStoresFilterBy(key: keyof StoreParams, value: string) {
    setAllStoresAllTenants((state) => ({
      ...state,
      [key]: value,
    }));
  }

  function allStoresSelectSortBy(value: string) {
    setAllStoresAllTenants((currentValue) => {
      return {
        ...currentValue,
        sortBy: value,
      };
    });
  }

  function allStoresSelectPage(value: number) {
    setAllStoresAllTenants((currentValue) => {
      return {
        ...currentValue,
        page: value,
      };
    });
  }

  function allStoresSelectRowsPerPage(value: number) {
    setAllStoresAllTenants((currentValue) => {
      return {
        ...currentValue,
        rowsPerPage: value,
      };
    });
  }

  function allStoresSelectReset() {
    setAllStoresAllTenants({
      content: [] as StoreListTypes[],
      name: "",
      tenantName: "",
      rowsPerPage: Number(DefaultRowsPerPage),
      page: 1,
      sortBy: "",
      totalDocuments: 1,
      totalPages: 1,
    });
  }

  const selectSortBy = (value: string) => {
    setSortBy(value);
  };

  const selectPage = (value: number) => {
    setPage(value);
  };

  const selectRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };

  useEffect(() => {
    if (location.pathname !== "/settings/users/create") {
      allStoresSelectReset();
    }
  }, [location.pathname]);

  return (
    <StoresContext.Provider
      value={{
        allStoresFilterBy,
        allStoresAllTenants,
        allStoresSelectSortBy,
        allStoresSelectPage,
        allStoresSelectRowsPerPage,
        allStoresSelectReset,
        allTenants,
        createStoreStep,
        editStore,
        fetchAllTenants,
        fetchAllStoresAllTenants,
        fetchStores,
        fetchStore,
        selectedStore,
        setCreateStoreStep,
        setSelectedStore,
        storesLoading,
        stores,
        selectPage,
        selectSortBy,
        selectRowsPerPage,
        // updateStoreStatus,
        page,
        rowsPerPage,
        totalPages,
      }}
    >
      {children}
    </StoresContext.Provider>
  );
}

import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const BreadcrumbContainer = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: hidden;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-800"]};
  }
`;

interface TextLinkProps {
  end: boolean;
}

export const TextLink = styled(NavLink)<TextLinkProps>`
  display: flex;
  flex: 1 1 0;
  min-width: 0;
  text-decoration: none;
  text-transform: capitalize;
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
  color: ${(props) => props.theme.colors["gray-800"]};
  white-space: nowrap;

  ${(props) =>
    props.end
      ? css`
          color: ${(props) => props.theme.colors["blue-600"]};
        `
      : css`
          &:hover {
            color: ${(props) => props.theme.colors["blue-600"]};
            text-decoration-color: ${(props) => props.theme.colors["blue-600"]};
            text-decoration: underline;
            transition: all 0.2s;
          }
        `};

  & > span {
    ${(props) =>
      props.end &&
      css`
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: normal;
      `}
  }
`;

import React from "react";
import { FiSkipBack, FiSkipForward } from "react-icons/fi";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useTheme } from "styled-components";
import * as S from "./styles";
import { SelectInput } from "../../../../SelectInput";
import { RowsPerPage } from "../../constants";

interface PaginationProps {
  page: number;
  totalPages: number;
  rowsPerPage: number;
  onFirstPage: () => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onLastPage: () => void;
  onChangeRowsPerPage: (rows: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  page,
  totalPages,
  rowsPerPage,
  onFirstPage,
  onPreviousPage,
  onNextPage,
  onLastPage,
  onChangeRowsPerPage,
}) => {
  const { colors } = useTheme();

  const totalItems = totalPages * rowsPerPage;
  const startIndex = (page - 1) * rowsPerPage + 1;
  const endIndex = Math.min(page * rowsPerPage, totalItems);

  const resultsText = `${startIndex}-${endIndex} of ${totalItems} Results`;

  return (
    <S.PaginationContainer>
      <S.RowsContainer>
        <span>Rows per page</span>
        <SelectInput
          options={RowsPerPage}
          onChange={(e) => onChangeRowsPerPage(parseInt(e.target.value))}
          value={rowsPerPage}
          variant="resized"
          width="3.75rem"
        />
      </S.RowsContainer>

      <S.PagesContainer>
        <div>
          <span>{`${page} of ${totalPages}`}</span>
        </div>

        <S.ButtonsWrapper>
          <S.PaginationButton disabled={page <= 1} onClick={onFirstPage}>
            <FiSkipBack size={20} color={colors["gray-850"]} />
          </S.PaginationButton>

          <S.PaginationButton disabled={page <= 1} onClick={onPreviousPage}>
            <IoChevronBack size={20} color={colors["gray-850"]} />
          </S.PaginationButton>

          <S.PaginationButton
            disabled={page >= totalPages}
            onClick={onNextPage}
          >
            <IoChevronForward size={20} color={colors["gray-850"]} />
          </S.PaginationButton>

          <S.PaginationButton
            disabled={page >= totalPages}
            onClick={onLastPage}
          >
            <FiSkipForward size={20} color={colors["gray-850"]} />
          </S.PaginationButton>
        </S.ButtonsWrapper>
      </S.PagesContainer>

      <S.MobileTopRow>
        <span>{resultsText}</span>

        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span>Rows per page</span>
          <SelectInput
            options={RowsPerPage}
            onChange={(e) => onChangeRowsPerPage(parseInt(e.target.value))}
            value={rowsPerPage}
            variant="resized"
            width="4.625rem"
          />
        </div>
      </S.MobileTopRow>

      <S.MobileBottomRow>
        <S.ButtonsWrapper>
          <S.PaginationButton disabled={page <= 1} onClick={onFirstPage}>
            <FiSkipBack size={20} color={colors["gray-850"]} />
          </S.PaginationButton>

          <S.PaginationButton disabled={page <= 1} onClick={onPreviousPage}>
            <IoChevronBack size={20} color={colors["gray-850"]} />
          </S.PaginationButton>

          <S.PaginationButton
            disabled={page >= totalPages}
            onClick={onNextPage}
          >
            <IoChevronForward size={20} color={colors["gray-850"]} />
          </S.PaginationButton>

          <S.PaginationButton
            disabled={page >= totalPages}
            onClick={onLastPage}
          >
            <FiSkipForward size={20} color={colors["gray-850"]} />
          </S.PaginationButton>
        </S.ButtonsWrapper>
      </S.MobileBottomRow>
    </S.PaginationContainer>
  );
};

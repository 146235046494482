import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const CardButtonContainer = styled(NavLink)`
  display: flex;

  padding: 1.5rem;

  width: 22.63rem;
  height: 10.69rem;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.SM};
  text-decoration: none;

  gap: 1rem;

  box-shadow: 0px 4px 8px 0px rgba(138, 138, 138, 0.08);

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["blue-600"]};
    transition: outline 0.2s;
  }
  @media (max-width: ${({ theme }) => theme.responsiveDesign.sm}) {
    width: 100%;
  }
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: ${(props) => props.theme.borderRadius.Circular};

  background-color: ${(props) => props.theme.colors["blue-200"]};

  svg {
    height: 2.5rem;
    width: 2.5rem;
    color: ${(props) => props.theme.colors["blue-600"]};
  }
`;

export const CardButtonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.75rem;
  width: 100%;
  max-width: 14rem;
`;

export const CardButtonTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSize.XXS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  color: ${(props) => props.theme.colors["black-600"]};
  font-family: ${(props) => props.theme.fontFamily.Roboto};
`;

export const CardButtonText = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  color: ${(props) => props.theme.colors["black-600"]};
  font-family: ${(props) => props.theme.fontFamily.Roboto};

  height: 48px;
`;

export const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors["gray-200"]};
  color: ${(props) => props.theme.newColors["blue-500"]};
  border: none;
  border-radius: 1rem;
  font-family: ${(props) => props.theme.fontFamily.Roboto};

  height: 2rem;
  width: 100%;
`;

import styled from "styled-components";

export const PaginationContainer = styled.section`
  width: 100%;
  margin-top: 0.75rem;
  padding: 1px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    height: 5.25rem;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
`;

export const RowsContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  span {
    color: ${(props) => props.theme.colors["gray-850"]};
    font-size: ${(props) => props.theme.fontSize.XXXS};
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: none;
  }
`;

export const PagesContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 1rem;

  div {
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-100"]};
    border-radius: 6px;
    padding: 0.25rem 0.75rem;
    height: 2rem;

    span {
      color: ${(props) => props.theme.colors["gray-850"]};
    }
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: none;
  }
`;

export const ButtonsWrapper = styled.section`
  display: flex;
  gap: 1rem;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    gap: 0.5rem;
    flex-shrink: 0;
    align-self: stretch;
    width: 100%;
    justify-content: space-between;
    height: 100%;
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors["gray-100"]};
  border-radius: 6px;

  height: 2rem;
  width: 2rem;

  &:not(:disabled):hover {
    filter: brightness(0.9);
    transition: filter 0.2s;
  }

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    flex: 1;
    height: 100%;
  }
`;

export const MobileTopRow = styled.div`
  display: none;
  font-size: 0.75rem;
  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const MobileBottomRow = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.responsiveDesign.sm}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.375rem;
  }
`;

import React, { useEffect, useState } from "react";
import { useContextSelector } from "use-context-selector";
import { PageHeader } from "../../../../components/Headers/PageHeader";
import { SearchInput } from "../../../../components/New/SearchInput";
import { SelectInput } from "../../../../components/SelectInput";
import { DuplicateItemsContext } from "../../../../contexts/DuplicateItemsContext";

import { useSearchParams } from "react-router-dom";
import { wrapperRequests } from "../../../../services/api";
import { routesURL } from "../../../../services/routesUrl";
import * as S from "./styles";
import { Checkbox } from "../../../../components/New/Checkbox";
import { CopyContainer } from "../../../../components/TableElements/components/CopyContainer";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import {
  CustomTable,
  CustomTableRow,
} from "../../../../components/New/Table/CustomTable";
import { useAuth } from "../../../../hooks/auth";
import { permissions } from "../../../../shared/Permissions";
import { Loading } from "../../../../components/New/Loading";
import { NoDataAvailableImage } from "../../../../components/NoDataAvailableImage";
import { DefaultRowsPerPage } from "../../../../components/New/Table/constants";

export interface DuplicateItemsProps {
  id: string;
  brands: string[];
  descriptions: string[];
  systemSkus: string[];
  tenantName: string;
  quantitiesOnHand: number[];
  upc?: string;
  sku?: string;
  ean?: string;
}

export function DuplicatedItems() {
  const { allTenants, fetchAllTenants } = useContextSelector(
    DuplicateItemsContext,
    (context) => {
      return context;
    },
  );

  const { user } = useAuth();

  const [newDuplicateItems, setNewDuplicateItems] = useState<
    DuplicateItemsProps[]
  >([]);
  const [collapsible, setCollapsible] = useState({
    active: false,
    itemId: "",
  });

  const [duplicatedItemsSearchParams, setDuplicatedItemsSearchParams] =
    useSearchParams();

  const [duplicatedItemsParams, setDuplicatedItemsParams] = useState({
    search: duplicatedItemsSearchParams.get("search") ?? "",
    brand: duplicatedItemsSearchParams.get("brand") ?? "",
    tenantName: duplicatedItemsSearchParams.get("tenantName") ?? "",
    pendingImport: duplicatedItemsSearchParams.get("pendingImport") === "true",
    page: duplicatedItemsSearchParams.get("page") ?? "1",
    rowsPerPage:
      duplicatedItemsSearchParams.get("rowsPerPage") ?? DefaultRowsPerPage,
    totalPages: duplicatedItemsSearchParams.get("totalPages") ?? "0",
  });

  const [loading, setLoading] = useState(false);

  const [searchType, setSearchType] = useState<"brand" | "identifier">(
    duplicatedItemsParams.brand ? "brand" : "identifier",
  );

  type UpdateDuplicatedItemsParamsProps = keyof typeof duplicatedItemsParams;

  const updatedParams = new URLSearchParams();

  const getDuplicatedItems = async () => {
    setLoading(true);
    try {
      const { data } = await wrapperRequests(
        routesURL.report.duplicatedItems.getDuplicatedItems,
        "GET",
        {
          params: {
            page: duplicatedItemsParams?.page,
            limit: duplicatedItemsParams?.rowsPerPage,
            tenantName: duplicatedItemsParams?.tenantName
              ? duplicatedItemsParams?.tenantName
              : undefined,
            brand: duplicatedItemsParams?.brand
              ? duplicatedItemsParams?.brand
              : undefined,
            search: duplicatedItemsParams?.search
              ? duplicatedItemsParams?.search
              : undefined,
            pendingImport: duplicatedItemsParams?.pendingImport,
          },
        },
      );

      setNewDuplicateItems(data.content);
      setDuplicatedItemsParams((currentState) => {
        return {
          ...currentState,
          totalPages: data?.totalPages ?? "0",
        };
      });
    } catch (error) {
      throw Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDuplicateItem = async (itemId: string) => {
    setLoading(true);
    try {
      const submitResponse = await wrapperRequests(
        routesURL.report.duplicatedItems.editDuplicatedItems,
        "PUT",
        {
          data: {
            ids: [itemId],
          },
        },
      );

      if (submitResponse.status === 200) {
        const removeItemFromList =
          newDuplicateItems &&
          newDuplicateItems.filter((item) => item.id !== itemId);

        setNewDuplicateItems(removeItemFromList);
      }
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setLoading(false);
    }
  };

  const updateInputSearchValues = async () => {
    duplicatedItemsParams?.search &&
      updatedParams.set("search", duplicatedItemsParams.search);
    duplicatedItemsParams?.brand &&
      updatedParams.set("brand", duplicatedItemsParams.brand);
    duplicatedItemsParams?.tenantName &&
      updatedParams.set("tenantName", duplicatedItemsParams.tenantName);
    updatedParams.set(
      "pendingImport",
      String(duplicatedItemsParams.pendingImport),
    );
    updatedParams.set("rowsPerPage", String(duplicatedItemsParams.rowsPerPage));
    updatedParams.set("page", "1");

    setDuplicatedItemsSearchParams(updatedParams);
  };

  const updateDuplicatedItemsParams = (
    key: UpdateDuplicatedItemsParamsProps,
    value: any,
  ) => {
    setDuplicatedItemsParams((currentValue) => {
      return {
        ...currentValue,
        [key]: value,
      };
    });
  };

  const updatePendingImport = (value: boolean) => {
    setDuplicatedItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("pendingImport", String(value));
      return updatedParams;
    });
  };

  const updatePage = (value: number) => {
    setDuplicatedItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("page", String(value));
      return updatedParams;
    });
  };

  const updateRows = (value: number) => {
    setDuplicatedItemsSearchParams((params) => {
      const updatedParams = new URLSearchParams(params.toString());
      updatedParams.set("rowsPerPage", String(value));
      updatedParams.set("page", "1");
      return updatedParams;
    });
  };

  const handleChangeSearchType = () => {
    if (searchType === "identifier") {
      return setSearchType("brand");
    }

    return setSearchType("identifier");
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchType === "identifier") {
      updateDuplicatedItemsParams("brand", "");
      return updateDuplicatedItemsParams("search", event.target.value);
    }

    updateDuplicatedItemsParams("search", "");
    return updateDuplicatedItemsParams("brand", event.target.value);
  };

  useEffect(() => {
    fetchAllTenants();
    getDuplicatedItems();
  }, []);

  const collapsibleTdStyle = ({
    isLast,
    isFirst,
  }: {
    isLast?: boolean;
    isFirst?: boolean;
  }) => (isFirst || isLast ? {} : { borderBottom: "none" });

  return (
    <S.DuplicateItemsContainer>
      <PageHeader
        title="Duplicated Items"
        description={
          duplicatedItemsParams.pendingImport === false
            ? "List of items that are currently duplicated in Lightspeed. Check the box next to each duplicated item listed on this page. Then, log in to Lightspeed and resolve the duplicated products"
            : "These items have been checked as merged and are waiting to be updated. Uncheck them if they have not been merged yet"
        }
        hasBorder={false}
        refreshFunction={() =>
          setDuplicatedItemsSearchParams({
            pendingImport: String(duplicatedItemsParams.pendingImport),
          })
        }
        customBreadcrumbString="Duplicated Items"
      />

      <S.DuplicateItemsHeaderButtonContainer>
        <S.DuplicateItemsHeaderButton
          active={duplicatedItemsParams.pendingImport === false}
          onClick={() => {
            updatePendingImport(false);
            duplicatedItemsParams.pendingImport !== false &&
              updateDuplicatedItemsParams("pendingImport", false);
          }}
        >
          Pending Merge
        </S.DuplicateItemsHeaderButton>

        <S.DuplicateItemsHeaderButton
          active={duplicatedItemsParams.pendingImport === true}
          onClick={() => {
            updatePendingImport(true);
            duplicatedItemsParams.pendingImport !== true &&
              updateDuplicatedItemsParams("pendingImport", true);
          }}
        >
          Pending Import
        </S.DuplicateItemsHeaderButton>
      </S.DuplicateItemsHeaderButtonContainer>

      <SearchInput
        disabled={loading}
        handleChangeSearch={handleChangeSearchType}
        onPressEnter={() => updateInputSearchValues()}
        onChange={(event) => handleSearch(event)}
        value={
          searchType === "identifier"
            ? duplicatedItemsParams.search || ""
            : duplicatedItemsParams.brand || ""
        }
        filterDropdown={{
          disabled: loading,
          selectInput: (
            <SelectInput
              placeholder="Select an option"
              options={allTenants.map((tenantName) => ({
                id: tenantName.id,
                value: tenantName.name,
                label: tenantName.name,
              }))}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                updateDuplicatedItemsParams("tenantName", event.target.value);
              }}
              value={duplicatedItemsParams.tenantName ?? ""}
              variant="resized"
            />
          ),
          onFilterClick: () => updateInputSearchValues(),
          onResetClick: () => updateDuplicatedItemsParams("tenantName", ""),
        }}
        placeholder={
          searchType === "identifier"
            ? "Search by UPC/SKU/EAN/Description"
            : "Search by Brand"
        }
      />

      {loading && <Loading />}

      {newDuplicateItems.length === 0 && !loading && <NoDataAvailableImage />}

      <>
        {newDuplicateItems.length > 0 && !loading && (
          <>
            <CustomTable
              headers={[
                "",
                "Region",
                "UPC/SKU/EAN",
                "System ID",
                "Region QOH",
                "Brand",
                "Description",
              ]}
              onSortClick={setNewDuplicateItems}
              sortKeys={[
                "",
                "tenantName",
                "upc",
                "systemSkus",
                "quantitiesOnHand",
                "brands",
                "descriptions",
              ]}
              style={{
                marginTop: "0.75rem",
                overflow: "visible",
              }}
              pagination={{
                rowsPerPage: Number(duplicatedItemsParams.rowsPerPage),
                selectPage: updatePage,
                page: Number(duplicatedItemsParams.page),
                selectRowsPerPage: updateRows,
                totalPages: Number(duplicatedItemsParams.totalPages),
              }}
            >
              {newDuplicateItems &&
                newDuplicateItems.map((item) => {
                  const skuQuantity = item.systemSkus.length;
                  const hasMoreThanTwoSku = skuQuantity > 2;

                  return (
                    <React.Fragment key={item.id}>
                      <CustomTableRow>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "2rem",
                            paddingRight: "0",
                          }}
                        >
                          <Checkbox
                            checked={false}
                            onClick={() => handleSubmitDuplicateItem(item.id)}
                          />
                        </S.StyledTd>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "7.5rem",
                          }}
                          isPendingImport={duplicatedItemsParams.pendingImport}
                        >
                          {user.user.permissions.includes(
                            permissions.report.duplicatedItems.add,
                          ) && <span>{item.tenantName}</span>}
                        </S.StyledTd>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "9rem",
                          }}
                          isPendingImport={duplicatedItemsParams.pendingImport}
                        >
                          <S.GapContainer>
                            {item && item.upc && (
                              <div>
                                <S.SkuUpcEanText>
                                  <CopyContainer stringValue={item.upc} />
                                </S.SkuUpcEanText>
                              </div>
                            )}

                            {item && item.sku && (
                              <div>
                                <S.SkuUpcEanText>
                                  <CopyContainer stringValue={item.sku} />
                                </S.SkuUpcEanText>
                              </div>
                            )}

                            {(!item.upc || !item.sku) && item.ean && (
                              <div>
                                <S.SkuUpcEanText>
                                  <CopyContainer stringValue={item.ean} />
                                </S.SkuUpcEanText>
                              </div>
                            )}
                          </S.GapContainer>
                        </S.StyledTd>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "10rem",
                          }}
                          isPendingImport={duplicatedItemsParams.pendingImport}
                        >
                          {hasMoreThanTwoSku ? (
                            <CopyContainer
                              extraRecords={skuQuantity}
                              stringValue={item.systemSkus[0]}
                            />
                          ) : (
                            <S.GapContainer>
                              {item.systemSkus.map((sku, index) => (
                                <CopyContainer key={index} stringValue={sku} />
                              ))}
                            </S.GapContainer>
                          )}
                        </S.StyledTd>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "7.5rem",
                          }}
                          isPendingImport={duplicatedItemsParams.pendingImport}
                        >
                          {hasMoreThanTwoSku ? (
                            <S.SystemIDContainer>
                              <span>{item.quantitiesOnHand[0]}</span>
                            </S.SystemIDContainer>
                          ) : (
                            <S.GapContainer>
                              {item.quantitiesOnHand.map((qoh, index) => (
                                <span key={index}>{qoh}</span>
                              ))}
                            </S.GapContainer>
                          )}
                        </S.StyledTd>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "8.15rem",
                          }}
                          isPendingImport={duplicatedItemsParams.pendingImport}
                        >
                          {item.brands[0]}
                        </S.StyledTd>
                        <S.StyledTd
                          style={{
                            ...collapsibleTdStyle({
                              isFirst: collapsible.itemId !== item.id,
                            }),
                            width: "6.25rem",
                          }}
                          isPendingImport={duplicatedItemsParams.pendingImport}
                        >
                          {hasMoreThanTwoSku ? (
                            <S.DescriptionContainer>
                              <S.DescriptionCollapsibleContainer>
                                <span>- {item.descriptions[0]}</span>
                              </S.DescriptionCollapsibleContainer>
                              {collapsible.active &&
                              collapsible.itemId === item.id &&
                              collapsible.active ? (
                                <S.CollapsibleButton
                                  onClick={() =>
                                    setCollapsible((currentValue) => {
                                      return {
                                        active: !currentValue.active,
                                        itemId: currentValue.itemId
                                          ? ""
                                          : item.id,
                                      };
                                    })
                                  }
                                >
                                  <MdKeyboardArrowUp size={24} />
                                </S.CollapsibleButton>
                              ) : (
                                <S.CollapsibleButton
                                  onClick={() =>
                                    setCollapsible((currentValue) => {
                                      return {
                                        active: !currentValue.active,
                                        itemId: currentValue.itemId
                                          ? ""
                                          : item.id,
                                      };
                                    })
                                  }
                                >
                                  <MdKeyboardArrowDown size={24} />
                                </S.CollapsibleButton>
                              )}
                            </S.DescriptionContainer>
                          ) : (
                            <S.DescriptionContainer>
                              <div>
                                {item.descriptions.map((item, index) => (
                                  <span key={index}>- {item}</span>
                                ))}
                              </div>
                            </S.DescriptionContainer>
                          )}
                        </S.StyledTd>
                      </CustomTableRow>
                      {collapsible.active &&
                        collapsible.itemId === item.id &&
                        item.systemSkus.slice(1).map((sku, index, array) => {
                          const isLast = index === array.length - 1;

                          return (
                            <CustomTableRow variant="collapsible" key={index}>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                              ></S.StyledTd>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                                isPendingImport={
                                  duplicatedItemsParams.pendingImport
                                }
                              >
                                {item.tenantName}
                              </S.StyledTd>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                                isPendingImport={
                                  duplicatedItemsParams.pendingImport
                                }
                              >
                                <S.GapContainer>
                                  {item && item.upc && (
                                    <div>
                                      <S.SkuUpcEanText>
                                        <CopyContainer stringValue={item.upc} />
                                      </S.SkuUpcEanText>
                                    </div>
                                  )}

                                  {item && item.sku && (
                                    <div>
                                      <S.SkuUpcEanText>
                                        <CopyContainer stringValue={item.sku} />
                                      </S.SkuUpcEanText>
                                    </div>
                                  )}

                                  {(!item.upc || !item.sku) && item.ean && (
                                    <div>
                                      <S.SkuUpcEanText>
                                        <CopyContainer stringValue={item.ean} />
                                      </S.SkuUpcEanText>
                                    </div>
                                  )}
                                </S.GapContainer>
                              </S.StyledTd>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                                isPendingImport={
                                  duplicatedItemsParams.pendingImport
                                }
                              >
                                <span>
                                  <CopyContainer stringValue={sku} />
                                </span>
                              </S.StyledTd>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                                isPendingImport={
                                  duplicatedItemsParams.pendingImport
                                }
                              >
                                {item.quantitiesOnHand[index + 1]}
                              </S.StyledTd>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                                isPendingImport={
                                  duplicatedItemsParams.pendingImport
                                }
                              >
                                {item.brands[0]}
                              </S.StyledTd>
                              <S.StyledTd
                                style={collapsibleTdStyle({
                                  isLast,
                                })}
                                isPendingImport={
                                  duplicatedItemsParams.pendingImport
                                }
                              >
                                - {item.descriptions[index + 1]}
                              </S.StyledTd>
                            </CustomTableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </CustomTable>
          </>
        )}
      </>
    </S.DuplicateItemsContainer>
  );
}
